import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Modal from '../common/Modal.component';
import MarketFilters from '../MarketFilter/MarketFilter.component';
import OutcomeFilter from '../common/OutcomeFilter/OutcomeFilter.component';
import SportsFilterModal from '../SportsFilter/SportsFilterModal.component';
import FiltersButton from './FiltersButton.component';
import {
  clearActivityFilters,
  toggleOutcomeFilterAndSynchUrl,
  toggleMarketTypeIdFilterAndSynchUrl,
  toggleSportFilterAndSynchUrl,
} from '#/utils/slices/activityFiltersSlice';
import { useAppDispatch } from '#/utils/store';
import {
  ActivityOutcome,
  SportEnums,
  SportEnumValues,
  stringToSportEnum,
} from '#/utils/backend';

const BetsFilters = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // Local state to hold filter values before applying
  const [localOutcomeFilters, setLocalOutcomeFilters] = useState<
    Record<ActivityOutcome, boolean>
  >({} as Record<ActivityOutcome, boolean>);
  const [localSportFilters, setLocalSportFilters] = useState<
    Record<SportEnums, boolean>
  >({} as Record<SportEnums, boolean>);
  const [localMarketTypeIds, setLocalMarketTypeIds] = useState<
    Record<number, boolean>
  >({});
  const activeTab = searchParams.get('tab') || 'betActivity';
  const isOnSettledTab =
    activeTab === 'betActivity' &&
    searchParams.get('subItem') === 'settledBets';

  // Sync initial state from Redux and URL params into local state when modal opens
  useEffect(() => {
    const initOutcomes = searchParams.getAll('outcome') as ActivityOutcome[];
    const initSports = searchParams.getAll('sport') as SportEnumValues[];
    const initMarketTypeIds = searchParams.getAll('market').map(Number);

    const outcomeObj: Record<ActivityOutcome, boolean> = {} as Record<
      ActivityOutcome,
      boolean
    >;
    initOutcomes.forEach((outcome) => (outcomeObj[outcome] = true));

    const sportObj: Record<SportEnumValues, boolean> = {} as Record<
      SportEnumValues,
      boolean
    >;
    initSports.forEach((sport) => (sportObj[sport] = true));

    const marketTypeObj: Record<number, boolean> = {};
    initMarketTypeIds.forEach((id) => (marketTypeObj[id] = true));

    setLocalOutcomeFilters(outcomeObj);
    setLocalSportFilters(sportObj);
    setLocalMarketTypeIds(marketTypeObj);
  }, [searchParams]);

  // Apply filters to Redux and URL when Apply is clicked
  const applyFilters = () => {
    // Get active outcomes
    const activeOutcomes = Object.keys(localOutcomeFilters).filter(
      (key) => localOutcomeFilters[key as ActivityOutcome],
    ) as ActivityOutcome[];

    // Convert string keys to SportEnums and filter out any undefined values using a type guard
    const activeSports = (
      Object.keys(localSportFilters) as Array<keyof typeof localSportFilters>
    ) // Cast keys to enum type
      .filter((key) => localSportFilters[key]) // Only include sports that are `true`
      .map((key) => stringToSportEnum(key)) // Convert string keys to SportEnums
      .filter((sport): sport is SportEnumValues => sport !== undefined); // Ensure only valid SportEnums

    // Get active market types
    const activeMarketTypes = Object.keys(localMarketTypeIds)
      .map(Number)
      .filter((id) => localMarketTypeIds[id]);

    // Sync to URL params and Redux store
    dispatch(
      toggleOutcomeFilterAndSynchUrl({
        outcome: activeOutcomes,
        searchParams,
        setSearchParams,
      }),
    );

    dispatch(
      toggleMarketTypeIdFilterAndSynchUrl({
        marketTypeIds: activeMarketTypes,
        searchParams,
        setSearchParams,
      }),
    );

    dispatch(
      toggleSportFilterAndSynchUrl({
        sport: activeSports,
        searchParams,
        setSearchParams,
      }),
    );

    onClose(); // Close the modal after applying filters
  };

  // Clear filters from both local state and Redux store
  const clearFilters = () => {
    setLocalOutcomeFilters({} as Record<ActivityOutcome, boolean>);
    setLocalSportFilters({} as Record<SportEnums, boolean>);
    setLocalMarketTypeIds({});

    dispatch(clearActivityFilters({ searchParams, setSearchParams }));
    onClose();
  };

  return (
    <Modal title="Filters" isOpen={isOpen} onClose={onClose}>
      <div className="w-full p-5">
        <div className="mb-5 overflow-y-auto space-y-3 max-h-[400px]">
          {isOnSettledTab ? (
            <OutcomeFilter
              localOutcomeFilters={localOutcomeFilters}
              setLocalOutcomeFilters={setLocalOutcomeFilters}
            />
          ) : null}
          <SportsFilterModal
            localSportFilters={localSportFilters}
            setLocalSportFilters={setLocalSportFilters}
          />
          <MarketFilters
            localMarketTypeIds={localMarketTypeIds}
            setLocalMarketTypeIds={setLocalMarketTypeIds}
          />
        </div>
        <FiltersButton
          clearFilters={clearFilters}
          applyFilters={applyFilters}
        />
      </div>
    </Modal>
  );
};

export default BetsFilters;
