/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  CheckInput,
  HeaderWrapper,
  Title,
  HeadDivider,
  FilterCardContainer,
  MarketItemContainer,
  MarketLabel,
} from '#/components/FilterCards/FilterCards.styled';
import { SAMPLE_SPORTS_LIST } from '#/constants/common/global-constants';
import { SportEnums, stringToSportEnum } from '#/utils/backend';

// Convert SAMPLE_SPORTS_LIST to SportEnums values
const sportsOptions = SAMPLE_SPORTS_LIST.map((sport) =>
  stringToSportEnum(sport.name),
).filter((sport): sport is SportEnums => sport !== undefined);

const SportsFilterModal = ({
  localSportFilters,
  setLocalSportFilters,
}: {
  localSportFilters: Record<SportEnums, boolean>;
  setLocalSportFilters: (filters: Record<SportEnums, boolean>) => void;
}) => {
  // Handle the sport selection toggle
  const handleSportChange = (sport: SportEnums) => {
    setLocalSportFilters({
      ...localSportFilters,
      [sport]: !localSportFilters[sport], // Toggle the sport filter
    });
  };

  return (
    <>
      <div className={HeaderWrapper}>
        <span className={Title}>Sport</span>
      </div>
      <hr className={HeadDivider} />
      <div className={FilterCardContainer}>
        <ul>
          {sportsOptions.map((sport) => (
            <li
              className={MarketItemContainer}
              key={sport}
              onClick={() => handleSportChange(sport)}
            >
              <input
                className={`${CheckInput} !mr-0`}
                type="checkbox"
                id={sport}
                checked={localSportFilters[sport] || false}
                onChange={() => {}} // No need to handle change directly here
              />
              <span className={MarketLabel}>{sport}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SportsFilterModal;
