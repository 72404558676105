import Link from '#/components/common/Link.component';
import { SportEnums } from '#/utils/backend';
import {
  InnerWrapperListItem,
  SportIconWrapper,
  SportLabel,
  SportListItemWrapper,
  SportListWrapper,
  SportsIcon,
} from './SportsFilter.styled';

/** Converts a word to Camel Case */
export function toCamelCase(key: string) {
  if (!key) return '';
  const arr = key.split('');
  arr[0] = arr[0].toUpperCase();
  return arr.join('');
}

type CardProps = {
  sportlist: Sport[];
  selectedSport: SportEnums;
  basePath?: string;
};

/**
 * Sport Interface *
 * @export
 * @typedef {Sport}
 */
export type Sport = {
  sport_uid: string;
  name: string;
  image_light: string;
  image_dark: string;
  is_default: boolean;
};

export interface Filters {
  label: string;
  value: FilterList[];
  list: FilterList[];
  type: string;
  name?: string;
  id: string;
}

type TabParams = Record<string, string>[];

type DAY_TYPE_DISPLAY = 'day' | 'week' | 'none';

export interface TabOptions {
  label: string;
  key: string;
  params: TabParams;
  display_type: DAY_TYPE_DISPLAY;
}

export type FilterList = {
  id: string;
  type: string;
  name: string;
  uid: string;
  xid: string;
};

export default function SportsFilter({
  sportlist,
  selectedSport,
  basePath,
}: CardProps) {
  return (
    <div>
      <div className={SportListWrapper}>
        {sportlist?.map((sport) => {
          const isActive =
            selectedSport.toLowerCase() === sport.sport_uid.toLowerCase();
          const fullPath = `${basePath ? basePath : ''}/${sport.sport_uid}`;
          return (
            <div
              className={SportListItemWrapper({
                isDisabled: false,
                isActive,
              })}
              key={sport.sport_uid}
            >
              <Link
                to={fullPath.toLowerCase()}
                className={InnerWrapperListItem({
                  isActive,
                })}
                id={`${sport.sport_uid.toLowerCase()}_sport_selected`}
              >
                <span className={SportIconWrapper}>
                  <img
                    className={SportsIcon}
                    loading="lazy"
                    src={isActive ? sport.image_dark : sport.image_light}
                    alt={`${sport?.name?.toLowerCase()} icon`}
                  />
                </span>
                <span className={SportLabel}>{toCamelCase(sport?.name)}</span>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
