import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAppSelector } from '#/utils/store';
import env from '#/utils/env';
import { MAIN_MARKET_BANNER_TEXT } from '#/constants/common/global-constants';
import { MAINNET_CHAIN_ID } from '#/utils/chains';

const useSentryUser = () => {
  const user = useAppSelector((state) => state.users);

  useEffect(() => {
    if (user.auth0Id) {
      const defaultSentryUser = {
        id: user.auth0Id ?? '',
        username: user.username ?? '',
        name: user.name ?? '',
        wallet: user.wallet ?? '',
        sgeAddress: user.sgeAddress ?? '',
        oddsFormat: user.oddsFormat,
        timezone: user.timezone ?? '',
        kycStatus: user.kycStatus ?? null,
        isEmailVerified: user.isEmailVerified ?? false,
      };

      // redact email on mainnet
      const sentryUser =
        env.chain?.chain_id === MAINNET_CHAIN_ID
          ? defaultSentryUser
          : {
              email: user.email || '',
              ...defaultSentryUser,
            };

      Sentry.setUser(sentryUser);
    } else {
      Sentry.setUser(null);
    }
  }, [user]);
};

export default useSentryUser;
