import { useQuery } from '@tanstack/react-query';
import { loadBets } from '../backend';
import { useAppSelector } from '../store';

const useBetsDataLoader = (refetchInterval?: number) => {
  const userId = useAppSelector((state) => state.users.auth0Id);
  const sgeAddress = useAppSelector((state) => state.users.sgeAddress);

  return useQuery({
    queryKey: ['betsDataLoader', userId],
    // Only once the user has the sgeAddress stored, it means he/she is onboarded
    // and the backend is aware of the id
    enabled: !!userId && !!sgeAddress,
    queryFn: () => {
      if (userId && sgeAddress) {
        return loadBets(userId);
      }
      return null;
    },
    refetchInterval: refetchInterval ? refetchInterval : false, // Polling based on interval, disabled by default
  });
};

export default useBetsDataLoader;
