import { useCallback, useEffect, useState } from 'react';
import SportsFilter from '#/components/SportsFilter';
import ExploreMatchSpan from '#/components/common/ExploreButton/ExploreButton.component';
import Search from '#/components/common/Search/Search.component';
import SearchResults from '#/components/common/Search/SearchResults';
import { SAMPLE_SPORTS_LIST } from '#/constants/common/global-constants';
import Filters from '../Filters/Filters.component';
import { toast } from 'react-toastify';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import ScheduleFilters from '../common/ScheduleFilters';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import {
  removeLeague,
  setDateRange,
  setSearch,
  setSport,
} from '#/utils/slices/searchSlice';
import AppliedFilters from '../AppliedFiltersPanel';
import { useSearchLoader } from '#/utils/dataLoaders/useSearchLoader';
import { getTodayRange } from '#/utils/datetime/datetime';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { stringToSport } from '#/utils/backend';

export const FILTER_TYPES = {
  ALL: 'all',
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  UPCOMING: 'upcoming',
};

export default function Explore() {
  const [showFilters, setShowFilters] = useState(false);
  const dispatch = useAppDispatch();
  const searchQuery = useAppSelector((state) => state.search);
  const { pathname } = useLocation();
  const pathSegments = pathname.split('/').filter(Boolean);
  const basePath = `/${pathSegments[0] || ''}`;
  const sport = pathSegments[1] && stringToSport(pathSegments[1]);
  const { error } = useSearchLoader({ query: searchQuery });

  useEffect(() => {
    // Set default filter to 'today' when component mounts
    const now = new Date();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const { startsAfter, startsBefore } = getTodayRange(now, timezone);
    if (searchQuery.filters.leagueIds.length > 0) {
      dispatch(
        setDateRange({
          startsAfter,
          startsBefore: null,
        }),
      );
    } else {
      dispatch(
        setDateRange({
          startsAfter,
          startsBefore,
        }),
      );
    }
  }, [dispatch, searchQuery.filters.leagueIds]);

  useEffect(() => {
    if ((sport && sport === 'Cricket') || sport === 'Soccer') {
      dispatch(setSport(sport));
    }
  }, [sport]);

  useEffect(() => {
    if (error) {
      toast.error(<AppToast id={'UnableToFetch'} />, { icon: false });
    }
  }, [error]);

  const filters = searchQuery.leagues.map((league) => ({
    id: league.Id,
    label: league.Name,
  }));

  const handleRemoveFilter = (id: number) => {
    dispatch(removeLeague(id));
  };

  const setSearchInput = useCallback(
    (input: string) => {
      dispatch(setSearch(input));
    },
    [dispatch],
  );

  const sportTitle =
    searchQuery.sport?.replace('Soccer', 'Football') || 'Football';

  return (
    <div>
      <Helmet>
        <title>{`Explore ${sportTitle} | SixSigmaSports`}</title>
      </Helmet>
      <div className="px-4 pb-4">
        <SportsFilter
          sportlist={SAMPLE_SPORTS_LIST}
          selectedSport={sport || 'Soccer'}
          basePath={basePath}
        />
      </div>
      <div className={'px-4 pb-4'}>
        <ExploreMatchSpan />
      </div>
      <div className={'px-4 pb-4'}>
        <div className="flex items-center mb-4">
          <Search
            search={searchQuery.search}
            setSearch={setSearchInput}
            showSearchResults={true}
            onClick={() => setShowFilters(!showFilters)}
          />
        </div>
        {searchQuery.search.length ? (
          <SearchResults searchQuery={searchQuery} />
        ) : (
          <>
            <div className="pt-0">
              <ScheduleFilters />
            </div>
            <AppliedFilters
              filters={filters}
              onRemoveFilter={handleRemoveFilter}
            />
            <div className="pt-4">
              <SearchResults searchQuery={searchQuery} />
            </div>
          </>
        )}
      </div>
      {showFilters ? (
        <Filters isOpen={showFilters} onClose={() => setShowFilters(false)} />
      ) : null}
    </div>
  );
}
