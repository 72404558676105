/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  CheckInput,
  FilterCardContainer,
  HeadDivider,
  HeaderWrapper,
  MarketItemContainer,
  MarketLabel,
  Title,
} from '../FilterCards/FilterCards.styled';
import { MARKET_TYPES_MAP } from '#/utils/marketsMap';

const MarketFilters = ({
  localMarketTypeIds,
  setLocalMarketTypeIds,
}: {
  localMarketTypeIds: Record<number, boolean>;
  setLocalMarketTypeIds: (ids: Record<number, boolean>) => void;
}) => {
  const handleMarketChange = (marketTypeId: number) => {
    setLocalMarketTypeIds({
      ...localMarketTypeIds,
      [marketTypeId]: !localMarketTypeIds[marketTypeId],
    });
  };

  return (
    <>
      <div className={HeaderWrapper}>
        <span className={Title}>Market</span>
      </div>
      <hr className={HeadDivider} />
      <div className={FilterCardContainer}>
        <ul>
          {Object.values(MARKET_TYPES_MAP.map).map((marketType) => (
            <li
              key={marketType.id}
              className={MarketItemContainer}
              onClick={() => handleMarketChange(marketType.id)}
            >
              <input
                className={`${CheckInput} !mr-0`}
                type="checkbox"
                id={String(marketType.id)}
                checked={localMarketTypeIds[marketType.id] || false}
              />
              <span className={MarketLabel}>{marketType.marketName}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default MarketFilters;
