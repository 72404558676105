import * as t from 'io-ts';
import { isRight } from 'fp-ts/Either';

const fixture = t.type({
  id: t.number,
});

const league = t.type({
  id: t.number,
});

const sport = t.type({
  id: t.string,
});

const market = t.type({
  id: t.number,
  name: t.string,
  marketTypeId: t.number,
});

const outcome = t.type({
  id: t.string,
  position: t.number,
  name: t.string,
  acceptedRate: t.string,
});

const team = t.type({
  name: t.string,
});

const slippageParams = t.type({
  slippage: t.string, // Representing Decimal256 as a string
  odds: t.string, // Representing Decimal256 as a string
});

const houseProvisions = t.type({
  acceptableProvisionSize: t.string,
});

const betProvisions = t.type({
  acceptableBetSize: t.string,
});

const betSlip = t.type({
  fixture: fixture,
  market: market,
  outcome: outcome,
  homeTeam: team,
  awayTeam: team,
  league: league,
  sport: sport,
  slippageParams: t.union([slippageParams, t.null]), // Optional slippageParams
  betProvisions,
});

const houseSlip = t.type({
  fixture: fixture,
  market: market,
  homeTeam: team,
  awayTeam: team,
  houseProvisions,
});

const betSlipWithAmount = t.type({
  amount: t.string,
  slip: betSlip,
  rewardSpendingEligibility: t.boolean,
  usingRewards: t.boolean,
  rewardAmount: t.number,
  rewardEarningEligibility: t.boolean,
});

const betSlipWithEligibility = t.type({
  slip: betSlip,
  rewardSpendingEligibility: t.boolean,
  rewardEarningEligibility: t.boolean,
});

const houseSlipWithAmount = t.type({
  amount: t.string,
  slip: houseSlip,
});

const betSlips = t.record(t.string, betSlipWithAmount);
const houseSlips = t.record(t.string, houseSlipWithAmount);

export const decodeBetSlips = (input: unknown): BetSlips | undefined => {
  const decodedSlips = betSlips.decode(input);
  return isRight(decodedSlips) ? decodedSlips.right : undefined;
};

export const decodeHouseSlips = (input: unknown): HouseSlips | undefined => {
  const decodedSlips = houseSlips.decode(input);
  return isRight(decodedSlips) ? decodedSlips.right : undefined;
};

export type SlippageParams = t.TypeOf<typeof slippageParams>;
export type BetSlip = t.TypeOf<typeof betSlip>;
export type BetSlipWithEligibility = t.TypeOf<typeof betSlipWithEligibility>;
export type BetSlipWithAmount = t.TypeOf<typeof betSlipWithAmount>;
export type BetSlips = Record<string, BetSlipWithAmount>;

export type HouseSlip = t.TypeOf<typeof houseSlip>;
export type HouseSlipWithAmount = t.TypeOf<typeof houseSlipWithAmount>;
export type HouseSlips = Record<string, HouseSlipWithAmount>;
