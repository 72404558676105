import { Chain } from '@chain-registry/types';
import {
  staging,
  testnet,
  mainnet,
  STAGING_RPC,
  TESTNET_RPC,
  MAINNET_RPC,
} from '#/utils/chains';

export type Env = {
  wallets: {
    keplrEnabled: boolean;
    leapEnabled: boolean;
  };
  walletConnectProjectId: string;
  displayConnectionErrors: boolean;
  apiServer: string;
  rpc: string;
  chain: Chain | undefined;
  contract: string;
  blockExplorer: string;
  auth0: {
    domain: string;
    clientId: string;
    audience: string;
    redirectUri: string;
  };
  sentry?: {
    dsn: string;
    tracesSampleRate: number;
    replaysSessionSampleRate: number;
    replaysOnErrorSampleRate: number;
    profilesSampleRate: number;
    environment?: string;
  };
};

const PRODUCTION_DOMAIN = 'auth.sixsigmasports.app';
const STAGING_DOMAIN = 'auth.nonprod.sixsigmasports.app';
const TESTNET_DOMAIN = 'auth.nonprod.sixsigmasports.app';

const PRODUCTION = `https://sixsigmasports.app`;
const STAGING = 'https://staging.sixsigmasports.app';
const TESTNET = 'https://testnet.sixsigmasports.app';

const defaultSentryConfig = {
  dsn: 'https://0e3d1a049639689715110b880864a627@o1082226.ingest.us.sentry.io/4507863274618880',
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  profilesSampleRate: 0.1,
  environment: 'mainnet',
};

const stagingEnv: Env = {
  wallets: {
    keplrEnabled: true,
    leapEnabled: true,
  },
  walletConnectProjectId: '0592f75fa032204a69cda88879fcc53c',
  displayConnectionErrors: true,
  apiServer: 'https://api.staging.sixsigmasports.app',
  rpc: STAGING_RPC,
  chain: staging,
  contract: 'sge1uspvkgrr9gfw429de02a5gx6dpl5neaw8srx4zsdd0xdtpk7gstqagu49h',
  blockExplorer: 'https://blockexplorer.staging.sgenetwork.io',
  auth0: {
    domain: STAGING_DOMAIN,
    clientId: 'UsSkgwHPicdNYasm9wvtIaWDgZTYMiBp',
    audience: 'https://sixsigmasports.io',
    redirectUri: `${STAGING}/Soccer`,
  },
  sentry: {
    ...defaultSentryConfig,
    dsn: 'https://3ecef7bdb60a3cfcf44305a2d1edf091@o1082226.ingest.us.sentry.io/4507861545385984',
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    environment: 'staging',
  },
};

const testnetEnv: Env = {
  wallets: {
    keplrEnabled: true,
    leapEnabled: true,
  },
  walletConnectProjectId: '0592f75fa032204a69cda88879fcc53c',
  displayConnectionErrors: true,
  apiServer: 'https://api.testnet.sixsigmasports.app',
  rpc: TESTNET_RPC,
  chain: testnet,
  contract: 'sge1frq2hzkjtatsupc6jtyaz67ytydk9nya437q92qg76ny3y8fcnjsqcjzgg',
  blockExplorer: 'https://blockexplorer.testnet.sixsigmasports.io',
  auth0: {
    domain: TESTNET_DOMAIN,
    clientId: 'xnom6PaBhnAHRzP6ITuDBJukQZGImgJW',
    audience: 'https://testnet.sixsigmasports.io',
    redirectUri: `${TESTNET}/Soccer`,
  },
  sentry: {
    ...defaultSentryConfig,
    tracesSampleRate: 0.5,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    environment: 'testnet',
  },
};

const localhostEnv: Env = {
  ...stagingEnv,
  auth0: {
    ...stagingEnv.auth0,
    redirectUri: `${window.location.origin}/Soccer`,
  },
  sentry: undefined, // remove this to enable sentry locally
};

const productionEnv: Env = {
  wallets: {
    keplrEnabled: false,
    leapEnabled: true,
  },
  walletConnectProjectId: '0592f75fa032204a69cda88879fcc53c',
  displayConnectionErrors: false,
  apiServer: 'https://api.sixsigmasports.app',
  rpc: MAINNET_RPC,
  chain: mainnet,
  contract: 'sge17p9rzwnnfxcjp32un9ug7yhhzgtkhvl9jfksztgw5uh69wac2pgsg42h72',
  blockExplorer: 'https://blockexplorer.sgenetwork.io',
  auth0: {
    domain: PRODUCTION_DOMAIN,
    clientId: 'nPCYXGp0PsAX4xu2eqmdljbbwMCFUBx7',
    audience: 'https://mainnet.sixsigmasports.io/',
    redirectUri: `${PRODUCTION}/Soccer`,
  },
  sentry: {
    ...defaultSentryConfig,
    tracesSampleRate: 0.5,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
  },
};

const getEnv = () => {
  if (window.location.origin === PRODUCTION) {
    return productionEnv;
  } else if (window.location.origin === STAGING) {
    return stagingEnv;
  } else if (window.location.origin === TESTNET) {
    return testnetEnv;
  } else {
    return localhostEnv;
  }
};

const env = getEnv();

export default env;
