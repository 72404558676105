import mocks from '#/mocks/mocks.json';
import env from './env';
import { DeliverTxResponse } from '@cosmjs/stargate';
import { QueryParams } from './slices/searchSlice';
import { User } from '@auth0/auth0-react';
import { StatusType } from '#/components/common/ActivityCard/ActivityCard.types';
import { Tendermint37Client } from '@cosmjs/tendermint-rpc';
import { Event } from '@cosmjs/tendermint-rpc/build/tendermint37';
import * as Sentry from '@sentry/react';
import { OddsFormat } from './slices/usersSlice';

export type ActivityOutcome = 'Won' | 'Lost' | 'NoResult';

export type FixtureStatus =
  | 'NotStarted'
  | 'InProgress'
  | 'Postponed'
  | 'Cancelled'
  | 'Ended';

export type MarketState =
  | 'SettingUp'
  | 'Operational'
  | 'Suspended'
  | 'UnmetConditions'
  | 'Aborted'
  | 'InPlay'
  | 'Settled';

export type MarketProvision = {
  TotalBets: number;
  TotalLiquidity: string;
  TotalBetAmount: string;
};

export type Continent =
  | 'International'
  | 'Africa'
  | 'Asia'
  | 'Europe'
  | 'NorthAmerica'
  | 'Oceania'
  | 'SouthAmerica';

export type Country = {
  id: number;
  iso: string;
  lsports_name: string;
  continent: Continent;
};

export type League = {
  Id: number;
  Name: string;
  HasOperationalMarkets: boolean;
  Country: Country;
};

export type Team = {
  Id: number;
  Name: string;
};

export type Teams = {
  Away: Team;
  Home: Team;
};

export type OutcomeMovement = 'Up' | 'Down' | 'NoChange';

export type Odds = {
  lastUpdated: string;
  marketId: number;
  marketTypeId: number;
  movement: OutcomeMovement;
  outcomeName: string;
  outcomePosition: number;
  value: string;
  acceptableBetSize: string;
};

export type FixtureSummary = {
  id: number;
  fixtureId: number;
  fixtureName: string;
  fixtureStatus: FixtureStatus;
  marketStatus: MarketState;
  marketTypeName: string;
  marketTypeId: number;
  startDate: string;
  sport: SportEnums;
  acceptableProvisionSize: string;
  houseProvision: MarketProvision;
  league: League;
  teams: Teams;
  betLine: string | null;
  odds: {
    [key: string]: Odds;
  };
};

export type FixtureDetail = {
  id: number;
  sport: SportEnums;
  name: string;
  startDate: string;
  status: FixtureStatus;
  league: League;
  teams: Teams;
  markets: {
    [key: string]: Market[];
  };
};

export type Market = {
  id: number;
  marketTypeName: string;
  marketTypeId: number;
  status: MarketState;
  betLine: string | null;
  settlementOutcome: null; // TODO: What is this?
  acceptableProvisionSize: string;
  odds: {
    [key: string]: Odds;
  };
  houseProvision: MarketProvision;
};

export type HomepageData = {
  featured: FixtureSummary[];
  marketOfTheDay?: FixtureSummary;
  today: FixtureSummary[];
  trending: FixtureSummary[];
};

export type FixtureFilters = {
  leagues?: number[];
};

export type FixtureSearchQuery = {
  filters: FixtureFilters;
  offset?: number | null;
  search?: string | null;
};

export type FixtureSearchResults = {
  fixtures: FixtureSummary[];
  offset: number;
  total: number;
};

export type Transaction = {
  status: string;
  type: string;
  height: number;
  hash: string;
  createdAt: string;
};
export type Fixture = {
  id: number;
  league: League;
  markets: {
    [key: string]: FixtureSummary[];
  };
  name: string;
  sport: SportEnums;
  startDate: string;
  status: FixtureStatus;
  teams: Teams;
};

export type SportEnums = 'Soccer' | 'Cricket';

export enum SportEnumValues {
  Soccer = 'Soccer',
  Cricket = 'Cricket',
}

export const stringToSportEnum = (input: string): SportEnums | undefined => {
  switch (input.toLowerCase()) {
    case 'football':
      return SportEnumValues.Soccer;
    case 'soccer':
      return SportEnumValues.Soccer;
    case 'cricket':
      return SportEnumValues.Cricket;
    default:
      return undefined;
  }
};

export const stringToActivityOutcome = (
  input: string,
): ActivityOutcome | undefined => {
  switch (input.toLowerCase()) {
    case 'won':
      return 'Won';
    case 'lost':
      return 'Lost';
    case 'noresult':
      return 'NoResult';
    default:
      return undefined;
  }
};

export type BetState =
  | 'Submitted'
  | 'Active'
  | 'Settled'
  | 'Failed'
  | 'Refunded';

export type Bet = {
  activatedAt: string | undefined;
  fixtureId: string;
  fixtureName: string;
  id: string;
  odds: string;
  marketBetLine: string;
  marketId: number;
  marketTypeId: number;
  marketTypeName: string;
  outcomeId: string;
  outcomeName: string;
  result: string;
  stake: string;
  state: BetState;
  submittedAt: string;
  potentialWinnings: string;
  sport: SportEnums;
};

export type Engagement = {
  betAmount: string;
  betId: number;
  coveredAmount: string;
  date: string;
  odds: string;
  outcomeId: number | null;
  outcomeName: string | null;
  outcomePosition: number | null;
  txHash: string;
  riskExposure: string;
};

export type EngagementsResponse = {
  engagements: Engagement[];
  offset: number;
};

export const isSettledBet = (bet: Bet): boolean => {
  return (
    bet.state === 'Settled' ||
    bet.state === 'Failed' ||
    bet.state === 'Refunded'
  );
};

export const isSuccessfulBet = (bet: Bet): boolean => {
  return bet.state === 'Settled';
};

export const isActiveBet = (bet: Bet): boolean => {
  return bet.state === 'Submitted' || bet.state === 'Active';
};

export const getStatusType = (bet: Bet): StatusType | undefined => {
  const successful = isSuccessfulBet(bet) || isActiveBet(bet);
  if (isSettledBet(bet)) {
    return successful && (bet.result === 'Won' || isActiveBet(bet))
      ? 'success'
      : 'failure';
  }
  return successful ? 'success' : undefined;
};

const isSuccessfulHouseProvision = (houseProvision: HouseProvision) => {
  return (
    houseProvision.state === 'Settled' ||
    (houseProvision.profitOrLoss && parseFloat(houseProvision.profitOrLoss) > 0)
  );
};

export const isSettledHouseProvision = (
  houseProvision: HouseProvision,
): boolean => {
  return houseProvision.state === 'Settled';
};

export const isActiveHouseProvision = (houseProvision: HouseProvision) => {
  return houseProvision.state === 'Active';
};

export const getStatusTypeHouse = (
  houseProvision: HouseProvision,
): StatusType | undefined => {
  const successful =
    isSuccessfulHouseProvision(houseProvision) ||
    isActiveHouseProvision(houseProvision);
  if (isSettledHouseProvision(houseProvision)) {
    return successful ? 'success' : 'failure';
  }
  return successful ? 'success' : undefined;
};

export type BetsData = {
  bets: Bet[];
  total: number;
  offset: number;
};

export type HouseProvisionState = 'Active' | 'Settled' | 'Failed';

export type HouseProvision = {
  amount: string;
  createdAt: string;
  fixtureId: number;
  fixtureName: string;
  id: string;
  marketId: number;
  marketTypeId: number;
  marketTypeName: string;
  marketBetLine?: string;
  profitOrLoss?: string;
  state: HouseProvisionState;
  txHash: string;
  withdrawnAmount: string;
  totalBets: number;
  betValue: string;
  // WIP: this is not yet implemented on the backend
  riskExposure?: string;
};

export type HouseProvisionsData = {
  provisions: HouseProvision[];
  total: number;
  offset: number;
};

export type NotificationsData = {
  notifications: Notification[];
  offset: number;
  remaining: number;
};

export type Notification = {
  id: number;
  ty: NotificationType;
  date: string;
  isRead: boolean;
  metadataJson: string | null;
};

export type NotificationType =
  | 'BetSettlementWin'
  | 'BetSettlementLoss'
  | 'BetSettlementRefund'
  | 'BetPlacementFail'
  | 'HouseSettlement'
  | 'KycReminder'
  | 'KycApproved'
  | 'KycRejected'
  | 'KycPendingVerification'
  | 'KycResubmission'
  | 'RewardsReceipt';

export type TransactionsData = {
  data: Transaction[];
  offset: number;
  total: number;
};

export type FixtureTab = {
  id: string;
  name: string;
};

export type OddData = {
  [key: string]: Odds;
};

export type TxnBcResponse = {
  jsonrpc: string;
  id: number;
  result: {
    txs: {
      hash: string;
      height: string;
      index: number;
      tx: string;
      tx_result: DeliverTxResponse;
    }[];
    total_count: number;
  };
};

export type KYCToken = {
  token: string;
};

export const stringToSport = (input: string): SportEnums | undefined => {
  switch (input.toLowerCase()) {
    case 'soccer' || 'football':
      return 'Soccer';
    case 'cricket':
      return 'Cricket';
  }
};

export type OutcomeLiveData = {
  value: string;
  movement: string;
  acceptable_bet_size: string;
};

export type MarketLiveData = {
  status: string;
  acceptable_provision_size: string;
};

type OutcomesResponse = {
  outcomes: Record<string, OutcomeLiveData>;
  markets: Record<string, MarketLiveData>;
};

export interface Outcome {
  marketId: number;
  marketTypeId: number;
  outcomePosition: number;
}

export type RewardUnlockingCriteria = {
  odds: {
    min: number | null;
    max: number | null;
  } | null;
  main_market: boolean;
};

export type RewardEligibilityCriteria = {
  odds: {
    min: number | null;
    max: number | null;
  } | null;
  market_types: number[];
  sports: string[];
  leagues: number[]; //check this type
  fixtures: number[];
};

export type RewardBucket = {
  campaign_id: string;
  serial: number;
  amount: string;
  unlocks_at: string;
};

export type CampaignDetails = {
  id: string;
  start: string;
  end: string;
  amount: string;
  cap: number;
  eligibility_criteria: RewardEligibilityCriteria;
  ty: {
    bet_bonus: {
      eligibility: RewardEligibilityCriteria;
      payout_percentage: string;
      bet_size: {
        min: string | null;
        max: string | null;
      } | null;
    };
  };
  state: 'active' | 'inactive'; //check this type
  unlock_criteria: RewardUnlockingCriteria;
};

export const outcomeId = (outcome: Outcome): string => {
  return `${outcome.marketId}-${outcome.outcomePosition}`;
};

/**
 * Fetches data with error reporting via Sentry.
 * @param url The URL to fetch.
 * @param options Fetch options.
 * @returns The fetched data.
 */
async function fetchWithSentry<T>(
  url: string,
  options?: RequestInit,
): Promise<T> {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      const urlPath = new URL(url).pathname;
      const method = options?.method || 'GET';
      const errorMessage = `${method} ${urlPath} ${response.status}`;

      // Create a custom error with stack trace
      const error = new Error(errorMessage);
      error.name = 'FetchError';

      // Log the error with Sentry
      Sentry.captureException(error, {
        extra: {
          url,
          method,
          status: response.status,
          statusText: response.statusText,
        },
      });

      throw error;
    }
    return response.json();
  } catch (error) {
    // If it's not a FetchError, capture it with Sentry
    if (error instanceof Error && error.name !== 'FetchError') {
      Sentry.captureException(error);
    }
    throw error;
  }
}

export const loadFixtureData = async (id: string): Promise<FixtureDetail> => {
  return fetchWithSentry<FixtureDetail>(`${env.apiServer}/fixtures/${id}`);
};

export const searchTransaction = async (
  query: string,
): Promise<TxnBcResponse> => {
  return fetchWithSentry<TxnBcResponse>(`${env.rpc}tx_search?${query}`);
};

export const loadLeagues = async (): Promise<League[]> => {
  return fetchWithSentry<League[]>(`${env.apiServer}/leagues`);
};

export const loadKycToken = async (accessToken: string): Promise<KYCToken> => {
  return fetchWithSentry<KYCToken>(`${env.apiServer}/kyc_token`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const loadFixtureSearchData = async (
  queryObject: QueryParams,
  offset: number,
  pageSize: number,
): Promise<FixtureSearchResults> => {
  // TODO: validation of queryObject

  const updatedQuery = {
    search: queryObject.search,
    filters: {
      leagues: queryObject.filters.leagueIds,
      sport: queryObject.sport ?? 'Soccer',
      starts_after: queryObject.startsAfter,
      starts_before: queryObject.startsBefore,
      favourites_of: queryObject.favouritesOf,
    },
    offset: offset,
    limit: pageSize,
  };

  // Remove undefined properties
  Object.keys(updatedQuery.filters).forEach(
    (key) =>
      updatedQuery.filters[key as keyof typeof updatedQuery.filters] ===
        undefined &&
      delete updatedQuery.filters[key as keyof typeof updatedQuery.filters],
  );

  const query = `${encodeURIComponent(JSON.stringify(updatedQuery))}`;
  return fetchWithSentry<FixtureSearchResults>(
    `${env.apiServer}/fixtures/search?query=${query}`,
  ).catch((error) => {
    console.error('Error loading fixture search data:', error);
    throw error;
  });
};

export const loadHomepageData = async (
  sport: SportEnums,
): Promise<HomepageData> => {
  try {
    return fetchWithSentry<HomepageData>(
      withTz(`${env.apiServer}/homepage/${sport}`),
    );
  } catch (error) {
    console.error('Error loading homepage data:', error);
    throw error;
  }
};

export const withTz = (url: string): string => {
  const now = new Date().getTimezoneOffset();

  // Convert the timezone offset to hours and minutes, and apply the correct sign
  const sign = now > 0 ? '-' : '+'; // Positive value means behind UTC (west), negative means ahead (east)
  const absOffset = Math.abs(now); // Absolute value of the offset
  const hours = Math.floor(absOffset / 60); // Convert minutes to hours
  const minutes = absOffset % 60; // Get the remaining minutes

  // Format the timezone as hh:mm
  const formattedTz = `${sign === '+' ? '' : sign}${hours}:${minutes.toString().padStart(2, '0')}`;

  if (url.indexOf('?') > 0) {
    return `${url}&tz=${formattedTz}`;
  } else {
    return `${url}?tz=${formattedTz}`;
  }
};

export const reloadOutcomes = async (
  outcomeIds: string[],
  marketIds: number[],
): Promise<OutcomesResponse> => {
  const query = `${encodeURIComponent(JSON.stringify({ outcome_ids: outcomeIds, market_ids: marketIds }))}`;
  return fetchWithSentry<OutcomesResponse>(
    `${env.apiServer}/get-updates?query=${query}`,
  );
};

export const loadTransactions = async (
  offset: number,
  pageSize: number,
  address?: string,
  queryClient?: Tendermint37Client,
): Promise<TransactionsData> => {
  if (address && queryClient) {
    const page = Math.floor(offset / pageSize) + 1;
    const options = `&order_by="desc"&page=${page}&per_page=${pageSize}`;
    const senderTxs = await searchTransaction(
      `query="message.sender='${address}'"${options}`,
    );
    const recipientTxs = await searchTransaction(
      `query="transfer.recipient='${address}'"${options}`,
    );
    // Merge both sender and recipient transactions into one array
    const mergedList = Array.from(
      new Map(
        [...senderTxs.result.txs, ...recipientTxs.result.txs].map(
          (transaction) => [
            `${transaction.hash}-${transaction.height}`,
            transaction,
          ],
        ),
      ).values(),
      // Sort the merged list in descending order by 'height'
    ).sort((a, b) => +b.height - +a.height);
    const txnTimes = await Promise.all(
      mergedList.map((txn) => {
        return queryClient.block(+txn.height);
      }),
    );
    const formattedList = mergedList.map((item) => {
      const transfer = item.tx_result.events.find(
        (event) => event.type === 'transfer',
      );
      const type = getTransactionType(
        !transfer,
        address,
        item.tx_result.events,
      );
      const time = txnTimes.find(
        (event) => event.block.header.height === +item.height,
      );
      return {
        status: type === 'Failed' ? 'Failed' : 'Success',
        type: type,
        height: +item.height,
        hash: item.hash,
        createdAt: time ? time.block.header.time.toString() : 'N/A',
      };
    });
    return {
      data: formattedList,
      total: senderTxs.result.total_count + recipientTxs.result.total_count,
      offset: offset,
    };
  } else {
    throw Error('Connect your wallet first to see your transactions.');
  }
};

function getTransactionType(
  isFailed: boolean,
  userAddress: string,
  event: readonly Event[],
) {
  try {
    const msgTypeNames: { [prop: string]: string } = {
      '/cosmos.bank.v1beta1.MsgSend': 'Send Coin',
      '/cosmos.staking.v1beta1.MsgDelegate': 'Delegation',
      '/cosmos.staking.v1beta1.MsgUndelegate': 'Undelegation',
      '/cosmos.staking.v1beta1.MsgBeginRedelegate': 'Redelegation',
      '/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward':
        'Withdraw Reward',
      '/ibc.applications.transfer.v1.MsgTransfer': 'IBC Transfer',
      '/ibc.core.channel.v1.MsgAcknowledgement': 'IBC Acknowledgement',
      '/sgenetwork.sge.subaccount.MsgWithdrawUnlockedBalances':
        'Withdraw Reward',
      '/sgenetwork.sge.reward.MsgGrantReward': 'Reward Applied',
      'wasm-bet-submission': 'Bet Placement',
      'wasm-liquidity-provision': 'House Deposit',
      'wasm-withdraw-submission': 'House Withdrawn',
    };

    if (event?.length && !isFailed) {
      const message = event.find(
        (item1) => item1.type === 'message',
      )?.attributes;

      const actionName =
        message
          ?.find((item1) => item1.key.toString() === 'action')
          ?.value.toString() || '';
      if (actionName === '/cosmwasm.wasm.v1.MsgExecuteContract') {
        const contract = event.find((item1) => item1.type.includes('wasm'));
        if (contract?.type && msgTypeNames[contract.type]) {
          return msgTypeNames[contract.type];
        }
        return contract?.attributes.find(
          (item) => item.key.toString() === 'error_msg',
        )
          ? 'Failed'
          : 'N/A';
      }
      const name = message ? actionName : 'N/A';
      if (name === '/cosmos.bank.v1beta1.MsgSend') {
        const fromAddress = message
          ?.find((item1) => item1.key.toString() === 'sender')
          ?.value.toString();
        if (userAddress !== fromAddress) return 'Received Coin';
      }
      return msgTypeNames[name] || name;
    }
    return isFailed ? 'Failed' : 'N/A';
  } catch (err) {
    return 'Transaction';
  }
}

export const loadNotifications = async (
  accessToken: string,
  offset: number,
  tag: string,
): Promise<NotificationsData> => {
  const filter = {
    House: ['HouseSettlement'],
    Betting: [
      'BetSettlementWin',
      'BetSettlementLoss',
      'BetSettlementRefund',
      'BetPlacementFail',
    ],
    Other: [
      'KycReminder',
      'KycApproved',
      'KycRejected',
      'KycPendingVerification',
      'KycResubmission',
      'RewardsReceipt',
    ],
  }[tag];

  const query = `${encodeURIComponent(JSON.stringify({ offset, filter }))}`;
  return fetch(`${env.apiServer}/users/notifications?query=${query}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }).then((d) => d.json());
};

export const loadBets = async (userId: string): Promise<Bet[]> => {
  let bets: Bet[] = [];
  let offset = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const betsData = await loadPageOfBets(userId, offset);
    if (typeof betsData.bets === 'undefined' || betsData.bets.length === 0) {
      break;
    }
    bets = [...bets, ...betsData.bets];
    offset = bets.length;

    if (betsData.total <= bets.length) {
      break;
    }
  }

  return bets;
};

export const loadPageOfBets = async (
  userId: string,
  offset: number,
): Promise<BetsData> => {
  const query = `${encodeURIComponent(JSON.stringify({ offset }))}`;
  return fetchWithSentry<BetsData>(
    `${env.apiServer}/bets/${userId}?query=${query}`,
  );
};

export const loadLiquidityData = async (
  userId: string,
): Promise<HouseProvision[]> => {
  let houseProvisions: HouseProvision[] = [];
  let offset = 0;

  try {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const houseProvisionsData = await loadPageOfHouseProvisions(
        userId,
        offset,
      );

      if (
        typeof houseProvisionsData.provisions === 'undefined' ||
        houseProvisionsData.provisions.length === 0
      ) {
        break;
      }

      houseProvisions = [...houseProvisions, ...houseProvisionsData.provisions];
      offset = houseProvisions.length;

      if (houseProvisionsData.total <= houseProvisions.length) {
        break;
      }
    }

    return houseProvisions;
  } catch (error) {
    console.error('Error loading liquidity data:', error);
    throw error;
  }
};

export const loadPageOfHouseProvisions = async (
  userId: string,
  offset: number,
): Promise<HouseProvisionsData> => {
  const query = `${encodeURIComponent(JSON.stringify({ offset }))}`;
  return fetchWithSentry<HouseProvisionsData>(
    `${env.apiServer}/provisions/${userId}?query=${query}`,
  );
};

export const loadEngagementsData = async (
  provisionId: string,
  accessToken: string,
  offset?: number,
): Promise<Engagement[]> => {
  let engagements: Engagement[] = [];
  if (!offset) {
    offset = 0;
  }

  try {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const engagementsData = await loadPageOfEngagements(
        provisionId,
        accessToken,
        offset,
      );

      if (
        typeof engagementsData.engagements === 'undefined' ||
        engagementsData.engagements.length === 0
      ) {
        break;
      }

      engagements = [...engagements, ...engagementsData.engagements];
      offset = engagements.length;

      // Need clarification on if we will get total bet engagement count
      // See https://sixsigmasports.slack.com/archives/C07145Y6SF8/p1727180633429519?thread_ts=1726743294.326159&cid=C07145Y6SF8
      // if (engagementsData.total <= engagements.length) {
      //   break;
      // }

      break;
    }

    return engagements;
  } catch (error) {
    console.error('Error loading engagements data:', error);
    throw error;
  }
};

export const loadPageOfEngagements = async (
  provisionId: string,
  accessToken: string,
  offset: number,
): Promise<EngagementsResponse> => {
  const queryString = `${encodeURIComponent(JSON.stringify({ id: provisionId, offset }))}`;
  return fetchWithSentry<EngagementsResponse>(
    `${env.apiServer}/house-engagements/${provisionId}?query=${queryString}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

export const deactivateUser = async (
  accessToken: string,
): Promise<{ response: string }> => {
  const response = await fetch(`${env.apiServer}/users/deactivate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to deactivate user');
  }

  return response.json();
};

export type UserData = {
  id: string;
  kycSessionId?: string | null;
  kycStatus: KycStatus;
  walletAddr?: string | null;
};

export const updateUserTimeLimit = async (
  timeLimit: number,
): Promise<UserData> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const response = {
        ...mocks['/users/me'].post,
        timeLimit,
      } as unknown as BackendUserData;
      resolve(response);
    }, 1000);
  });
};

export type KycStatus =
  | 'APPROVED'
  | 'REJECTED'
  | 'RESUBMISSION_REQUIRED'
  | 'SUBMISSION_REQUIRED'
  | 'PENDING_VERIFICATION';

export type BackendUserData = {
  id: string;
  kycSessionId?: string | null;
  kycStatus: KycStatus;
  walletAddr?: string | null;
  oddsFormat: OddsFormat;
};

export const loadBackendUserData = async (
  accessToken: string,
): Promise<BackendUserData> => {
  return fetchWithSentry<BackendUserData>(`${env.apiServer}/users/me`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};

export const loadAuth0UserData = async (accessToken: string): Promise<User> => {
  return fetchWithSentry<User>(`https://${env.auth0.domain}/userinfo`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};

export const addFavouriteFixture = async (
  fixtureId: number,
  accessToken: string,
): Promise<void> => {
  await fetchWithSentry<void>(`${env.apiServer}/favourites/${fixtureId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const removeFavouriteFixture = async (
  fixtureId: number,
  accessToken: string,
): Promise<void> => {
  await fetchWithSentry<void>(`${env.apiServer}/favourites/${fixtureId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getFavouriteFixtures = async (
  accessToken: string,
  ids?: number[],
): Promise<number[]> => {
  const query = ids ? JSON.stringify({ ids }) : 'null';
  return fetchWithSentry<number[]>(
    `${env.apiServer}/favourites?query=${encodeURIComponent(query)}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

export type UserSettings = {
  timeLimit?: number | null;
  timeOutDuration?: number | null;
  oddsFormat?: OddsFormat | null;
};

export const postUserSettings = async (
  settingsData: UserSettings,
  accessToken: string,
): Promise<BackendUserData> => {
  const response = await fetch(`${env.apiServer}/users/settings`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${accessToken} `,
    },
    body: JSON.stringify({
      timeLimit: settingsData.timeLimit,
      timeOutDuration: settingsData.timeOutDuration,
      oddsFormat: settingsData.oddsFormat,
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to update user settings');
  }

  return response.json();
};
