import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CampaignDetails, RewardBucket } from '../backend';

export type State = {
  totalRewardsAvailable: number;
  totalRewardsUsed: number;
  campaignsApplicable: CampaignDetails[];
  rewardBuckets: RewardBucket[];
  betCapAvailable: number;
};

const initialState: State = {
  totalRewardsAvailable: 0,
  totalRewardsUsed: 0,
  campaignsApplicable: [],
  rewardBuckets: [],
  betCapAvailable: 0,
};

const slice = createSlice({
  name: 'rewardsCalculation',
  initialState,
  reducers: {
    setTotalRewardsAvailable: (state, data: PayloadAction<number>) => {
      state.totalRewardsAvailable = data.payload;
    },
    setCampaignsApplicable: (state, data: PayloadAction<CampaignDetails[]>) => {
      state.campaignsApplicable = data.payload;
    },
    setRewardBuckets: (state, data: PayloadAction<RewardBucket[]>) => {
      state.rewardBuckets = data.payload;
    },
    setBetCapUsed: (state, data: PayloadAction<number>) => {
      state.betCapAvailable = data.payload;
    },
  },
});

export const {
  setCampaignsApplicable,
  setTotalRewardsAvailable,
  setBetCapUsed,
  setRewardBuckets,
} = slice.actions;
export default slice.reducer;
