export const MatchCardContainer = `flex flex-wrap lg:items-center justify-between flex-row px-4 pt-5 5xl:px-6 5xl:pt-8 6xl:pt-12 relative lg:cursor-pointer items-start xl:flex-row xl:items-center 2xl:items-baseline relative`;
export const MatchContainer = ({
  $isDisabled,
  $isMainMarketFixture,
}: {
  $isDisabled: boolean;
  $isMainMarketFixture: boolean;
}) =>
  `overflow-y-hidden mb-4${$isMainMarketFixture ? ' border border-solid border-border-bg-2' : ''
  }${$isDisabled ? ' opacity-25 pointer-events-none' : ''}`;

export const MatchSeparator = `h-0 border border-solid border-t-0 border-card-bg-2`;
export const FlexContainer = `flex`;
export const FlexContainerBeHouse = `flex items-center my-0`;
export const StarIcon = `w-4 3xl:w-6 6xl:w-9 h-4 3xl:h-6 6xl:h-9 ml-3 md:mx-2 self-center cursor-pointer`;
// Style for V2
export const MatchCardWrapper = `flex flex-wrap lg:items-center justify-between flex-row relative lg:cursor-pointer items-start xl:flex-nowrap xl:items-center 2xl:items-baseline relative`;
export const FavIcon = `w-4 3xl:w-6 6xl:w-9 h-4 3xl:h-6 6xl:h-9 ml-1 md:mx-2 self-center cursor-pointer`;
export const MatchDivider = `h-0 border border-solid border-t-0 border-border-bg-1 mb-4`;
export const HouseParamContainer = `w-full flex space-x-3 mb-2`;
export const HouseParamItems = `w-full flex flex-col flex-1 mb-2 bg-card-bg-2 rounded-md py-2 px-2`;
export const ParamItemsText = `flex flex-col xl:flex-row text-white font-light tracking-wide text-xs xl:text-sm 3xl:text-base 5xl:text-lg`;
export const ParamValueItemsText = `text-white truncate max-w-14 xl:max-w-none inline-block font-bold text-base xxxs:text-lg xl:text-xl 3xl:text-2xl`;
export const SgeText = `text-white font-bold text-xxxs xl:text-xs 3xl:text-sm`;
export const ParamIcon = `w-3.5 xl:w-4 mt-0.5`;
export const ParamIconBet = `w-3 xl:w-4 mt-0.5`;
export const HouseIcon = `size-4 3xl:size-6 mr-2 3xl:mr-3`;
export const HouseButton = `w-full sm:w-80 tracking-wider rounded-md bg-brand-bg-1 px-4 py-3 text-black font-bold text-sm xxxs:text-base`;
export const MarketCardContainer = `border-b border-border-bg-1 bg-primary-bg-2 pb-4 mb-2`;
export const MarketHouseButton = `w-full sm:w-80 tracking-wider rounded-md bg-transparent px-4 py-3 text-white font-medium text-sm xxxs:text-base border border-border-bg-2`;
export const BeHouseText = `text-white font-semibold text-xxs xxxs:text-xs 3xl:text-base 4xl:text-lg uppercase`;
export const ParamValueWrapper = `w-full flex space-x-1 items-baseline whitespace-nowrap xl:mb-2`;

export const ForWebFlex = `lg:flex-1 lg:w-full`;
export const BetCapacityWrapper = `text-white text-sm 3xl:text-base border border-[#50525a] p-2 rounded-md`;
