/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import type { ReactNode } from 'react';

interface LinkPropTypes {
  children: ReactNode;
  type?: 'span' | 'div' | 'li' | 'h3' | 'p' | 'button';
  id?: string;
  className?: string;
  onClick: (prop: unknown) => void;
  ariaExpanded?: boolean;
  disabled?: boolean;
}

export default function ActionTag({
  children,
  type = 'div',
  id = 'action_tag_click',
  className,
  onClick,
  ariaExpanded = false,
  disabled = false,
}: LinkPropTypes) {
  switch (type) {
    case 'h3': {
      return (
        <h3
          className={className}
          onClick={onClick}
          role="button"
          tabIndex={0}
          id="action-tag-link"
          data-testid={id}
        >
          {children}
        </h3>
      );
    }
    case 'div': {
      return (
        <div
          className={className}
          onClick={onClick}
          role="button"
          tabIndex={0}
          id="action-tag-link"
          data-testid={id}
          aria-expanded={ariaExpanded}
        >
          {children}
        </div>
      );
    }
    case 'li': {
      return (
        <li
          className={className}
          onClick={onClick}
          role="button"
          tabIndex={0}
          id="action-tag-link"
          data-testid={id}
        >
          {children}
        </li>
      );
    }
    case 'p': {
      return (
        <p
          className={className}
          onClick={onClick}
          role="button"
          tabIndex={0}
          id="action-tag-link"
          data-testid={id}
        >
          {children}
        </p>
      );
    }
    case 'button': {
      return (
        <button
          className={className}
          onClick={onClick}
          tabIndex={0}
          id="action-tag-link"
          data-testid={id}
          disabled={disabled}
        >
          {children}
        </button>
      );
    }
    case 'span':
    default: {
      return (
        <span
          className={className}
          onClick={onClick}
          role="button"
          tabIndex={0}
          id="action-tag-link"
          data-testid={id}
        >
          {children}
        </span>
      );
    }
  }
}
