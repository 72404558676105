import {
  HouseButton,
  HouseParamContainer,
  HouseParamItems,
  MarketCardContainer,
  MarketHouseButton,
  ParamIcon,
  ParamIconBet,
  ParamItemsText,
  ParamValueItemsText,
  ParamValueWrapper,
  SgeText,
} from '../MatchCard/MatchCard.styled';
import Button from '#/components/common/Buttons/Button/Button.component';
import {
  BET_AMOUNT_ICON_BRAND,
  LIQUIDITY_ICON_BRAND,
  NO_OF_BETS_ICON,
} from '#/constants/common/images.constants';
import { useNavigate } from 'react-router-dom';
import { FixtureSummary } from '#/utils/backend';

type MatchHouseDetailsProps = {
  market: FixtureSummary;
  addHouseSlip: (market: FixtureSummary) => void;
  fixtureID: string;
};

export default function MatchHouseDetails({
  market,
  addHouseSlip,
  fixtureID,
}: MatchHouseDetailsProps) {
  const navigate = useNavigate();
  const isMarketDisabled =
    market.marketStatus !== 'Operational' ||
    market.fixtureStatus !== 'NotStarted';
  return (
    <div className={MarketCardContainer}>
      <div className={HouseParamContainer}>
        <div className={HouseParamItems}>
          <div className={ParamValueWrapper}>
            <span className={ParamValueItemsText}>
              {market.houseProvision.TotalBets}
            </span>
          </div>
          <div className="flex xl:items-center">
            <span className="mr-1 flex-none">
              <img className={ParamIconBet} src={NO_OF_BETS_ICON} alt="" />
            </span>
            <span className={ParamItemsText}>
              <span>Number of&nbsp;</span>
              <span>Bets</span>
            </span>
          </div>
        </div>

        <div className={HouseParamItems}>
          <div className={ParamValueWrapper}>
            <span className={ParamValueItemsText}>
              {market.houseProvision.TotalBetAmount}
            </span>
            <span className={SgeText}>SGE</span>
          </div>
          <div className="flex xl:items-center">
            <span className="mr-1 flex-none">
              <img className={ParamIcon} src={BET_AMOUNT_ICON_BRAND} alt="" />
            </span>
            <span className={ParamItemsText}>
              <span>Bet&nbsp;</span>
              <span>Amount</span>
            </span>
          </div>
        </div>
        <div className={HouseParamItems}>
          <div className={ParamValueWrapper}>
            <span className={ParamValueItemsText}>
              {market.houseProvision.TotalLiquidity}
            </span>
            <span className={SgeText}>SGE</span>
          </div>
          <div className="flex xl:items-center">
            <span className="mr-1 flex-none">
              <img className={ParamIcon} src={LIQUIDITY_ICON_BRAND} alt="" />
            </span>
            <span className={ParamItemsText}>
              <span>Committed&nbsp;</span>
              <span>Liquidity</span>
            </span>
          </div>
        </div>
      </div>
      <div className="lg:flex lg:space-x-4">
        <div className="mb-4">
          <Button
            className={HouseButton}
            disabled={isMarketDisabled}
            onClick={() => addHouseSlip(market)}
          >
            BE THE HOUSE
          </Button>
        </div>
        <div>
          <Button
            className={MarketHouseButton}
            onClick={() => navigate(`/match-detail/${fixtureID}`)}
          >
            VIEW ALL MARKETS
          </Button>
        </div>
      </div>
    </div>
  );
}
