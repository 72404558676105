import { EngagementCard } from '#/components/common/ActivityCard/ActivityCard.component';
import { HouseProvision } from '#/utils/backend';
import { LoaderBg } from './common/LoaderStyle/LoaderStyle.styled';
import EmptyList from './common/EmptyStates/EmptyState.component';
import useEngagementsDataLoader from '#/utils/dataLoaders/useEngagementsDataLoader';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';

const EngagementDetailsCard = (props: { provision: HouseProvision }) => {
  const { provision } = props;
  const { data: engagements, isLoading } = useEngagementsDataLoader(
    provision.id.toString(),
  );

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!engagements || !engagements.length) {
    return <EmptyList id="houseEngagements" mini={true} />;
  }

  const engagementDetails = engagements?.map((engagement, index) => {
    const infoItems = [
      { label: 'Selection:', value: engagement.outcomeName || 'N/A' },
      {
        label: 'Bet Amount:',
        value: `${parseFloat(engagement.betAmount).toFixed(2)} SGE`,
      },
      { label: 'Odds:', value: engagement.odds },
      {
        label: 'Risk Exposure:',
        value: `${parseFloat(engagement.riskExposure).toFixed(2)} SGE`,
      },
    ];
    return (
      <EngagementCard
        dateText={formatDateToDesiredFormat(engagement.date)}
        transactionLink={engagement.txHash}
        infoItems={infoItems}
        key={index}
      />
    );
  });

  return <div className="mt-2">{engagementDetails}</div>;
};

const LoadingComponent = () => {
  return (
    <div className="mt-4">
      <div className={`${LoaderBg} h-5 w-4/12 mr-24`}></div>
      <div className={`${LoaderBg} h-5 w-5/12`}></div>
    </div>
  );
};

export default EngagementDetailsCard;
