import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ActivityTab from '#/components/common/ActivityTab/ActivityTab.component';
import FilterButton from '#/components/common/FilterButton.component';
import Search from '#/components/common/Search/Search.component';
import BetsFilters from '#/components/Filters/BetsFilters.component';
import useBetsDataLoader from '#/utils/dataLoaders/useBetsDataLoader';
import useLiquidityDataLoader from '#/utils/dataLoaders/useLiquidityDataLoader';
import BetActivityCards from '../BetActivityCard';
import HouseActivityCards from '../HouseActivityCard';
import { toast } from 'react-toastify';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import {
  MARKET_TYPE_ID_URL_PARAM,
  OUTCOME_URL_PARAM,
  SPORT_URL_PARAM,
  setMarketTypeIdFilter,
  setOutcomeFilters,
  setSearch,
  setSportFilters,
} from '#/utils/slices/activityFiltersSlice';
import {
  ActivityOutcome,
  SportEnumValues,
  stringToActivityOutcome,
  stringToSportEnum,
} from '#/utils/backend';
import { SubTabs } from '../common/ActivityTab/SubTab.component';
import { Helmet } from 'react-helmet-async';

export default function MyBets(): JSX.Element {
  const [showFilters, setShowFilters] = useState(false);
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const searchInput = useAppSelector((state) => state.activityFilters.search);

  const setSearchInput = useCallback(
    (input: string) => {
      dispatch(setSearch(input));
    },
    [dispatch],
  );

  const {
    isLoading: isBetsLoading,
    data: betsData,
    error: betsError,
  } = useBetsDataLoader();

  const {
    isLoading: isLiquidityLoading,
    data: liquidityData,
    error: liquidityError,
    withdraw,
  } = useLiquidityDataLoader();
  const activeTab = searchParams.get('tab') || 'betActivity';
  const activeSubItem =
    searchParams.get('subItem') ||
    (activeTab === 'betActivity' ? 'activeBets' : 'active');

  if (betsError && activeTab === 'betActivity') {
    toast.error(<AppToast id={'UnableToFetch'} />, { icon: false });
  }

  if (liquidityError && activeTab === 'houseActivity') {
    toast.error(<AppToast id={'UnableToFetch'} />, { icon: false });
  }

  useEffect(() => {
    setSearchInput('');
  }, [activeTab, setSearchInput]);

  useEffect(() => {
    const outcomes = searchParams.getAll(OUTCOME_URL_PARAM);
    const typedOutcomes: ActivityOutcome[] = outcomes
      .map(stringToActivityOutcome)
      .filter((outcome): outcome is ActivityOutcome => !!outcome);

    dispatch(setOutcomeFilters(typedOutcomes));

    // Market Type IDs from URL params
    const marketTypeIds = searchParams.getAll(MARKET_TYPE_ID_URL_PARAM);
    const typedMarketTypeIds = marketTypeIds
      .map((marketTypeId) => parseInt(marketTypeId, 10))
      .filter((id) => id > 0);

    dispatch(setMarketTypeIdFilter(typedMarketTypeIds));

    // Sports from URL params
    const sports = searchParams.getAll(SPORT_URL_PARAM);
    const typedSports: SportEnumValues[] = sports
      .map((sport) => stringToSportEnum(sport))
      .filter((sport): sport is SportEnumValues => !!sport);

    dispatch(setSportFilters(typedSports));
  }, [dispatch, searchParams]);

  return (
    <div className="px-4">
      <Helmet>
        <title>{`My Bets | SixSigmaSports`}</title>
      </Helmet>
      <div className="mt-4">
        <ActivityTab />
      </div>
      <div className="flex items-center">
        <Search
          search={searchInput}
          setSearch={setSearchInput}
          showSearchResults={true}
        />
        <FilterButton onClick={() => setShowFilters(!showFilters)} />
      </div>
      <div className="mt-4">
        <SubTabs activeTab={activeTab} />
      </div>
      <div className="space-y-4 mt-4">
        {activeTab === 'betActivity' ? (
          <BetActivityCards
            searchInput={searchInput}
            data={betsData}
            isLoading={isBetsLoading || betsError}
            id={'data'}
            activeTab={activeTab}
            activeSubItem={activeSubItem}
          />
        ) : null}
        {activeTab === 'houseActivity' ? (
          <HouseActivityCards
            searchInput={searchInput}
            data={liquidityData}
            isLoading={isLiquidityLoading || liquidityError}
            id={'data'}
            activeTab={activeTab}
            activeSubItem={activeSubItem}
            withdraw={withdraw}
          />
        ) : null}
      </div>
      {showFilters && (
        <BetsFilters
          isOpen={showFilters}
          onClose={() => setShowFilters(false)}
        />
      )}
    </div>
  );
}
