import {
  AVATAR_IMG,
  BETS_ICON,
  BETS_ICON_ACTIVE,
  CALCULATOR_ICON,
  CALCULATOR_ICON_BRAND,
  CONTACTUS_ICON,
  CONTACTUS_ICON_BRAND,
  DOWNARROW_LIGHT_ICON,
  EDIT_ICON,
  EXPLORE_ICON,
  EXPLORE_ICON_BRAND,
  GAMBLING_ICON,
  GAMBLING_ICON_BRAND,
  HOME_ICON,
  HOME_ICON_BRAND,
  SSS_FULL_LOGO,
} from '#/constants/common/images.constants';
import { CONTACT_TEXT } from '../Footer/Footer.constants';
import { ConfigItem, MenuItem } from './SideMenuPanel/SideMenu.types';

export const menuItems: MenuItem[] = [
  {
    to: '/soccer',
    in: ['/soccer', '/cricket'],
    icon: HOME_ICON,
    iconActive: HOME_ICON_BRAND,
    text: 'Home',
    alt: 'Home icon',
    desktopOnly: true,
  },
  {
    to: '/explore',
    in: ['/explore', '/explore/soccer', '/explore/cricket'],
    icon: EXPLORE_ICON,
    iconActive: EXPLORE_ICON_BRAND,
    text: 'Explore All Matches',
    alt: 'Explore icon',
    desktopOnly: true,
  },
  {
    to: '/my-bets',
    icon: BETS_ICON,
    iconActive: BETS_ICON_ACTIVE,
    text: 'My Bets',
    alt: 'Bets icon',
    desktopOnly: true,
  },
  {
    to: '/responsible-gambling',
    icon: GAMBLING_ICON,
    iconActive: GAMBLING_ICON_BRAND,
    text: 'Responsible Gambling',
    alt: 'Gambling icon',
    desktopOnly: false,
  },
  {
    to: '/odds-conversion',
    icon: CALCULATOR_ICON,
    iconActive: CALCULATOR_ICON_BRAND,
    text: 'Odds Conversions',
    alt: 'Conversions icon',
    desktopOnly: false,
  },
  {
    onClick: () => {
      window.zE && window.zE.activate({ hideOnClose: true });
    },
    icon: CONTACTUS_ICON,
    iconActive: CONTACTUS_ICON_BRAND,
    text: CONTACT_TEXT,
    alt: 'Contact icon',
    desktopOnly: false,
  },
];

export const configItems: ConfigItem[] = [
  {
    label: 'Odds',
    value: 'Decimal',
    icon: DOWNARROW_LIGHT_ICON,
    alt: 'Arrow Icon',
  },
  {
    label: 'Currency',
    value: 'USD',
    icon: DOWNARROW_LIGHT_ICON,
    alt: 'Arrow Icon',
  },
  {
    label: 'Language',
    value: 'English',
    icon: DOWNARROW_LIGHT_ICON,
    alt: 'Arrow Icon',
  },
];

export const user = {
  avatar: AVATAR_IMG,
  name: 'John Doe',
  username: 'MaverickWager',
  editIcon: EDIT_ICON,
  alt: 'Avatar Image',
};

export const logo = {
  src: SSS_FULL_LOGO,
  alt: 'Six Sigma Sports Logo',
};
