import ActionTag from '#/components/common/ActionTags.component';
import { getIsMarketSelected } from '#/components/MatchDetails/matches.utils';
import {
  BeTheHouseCTA,
  HouseParamsContainer,
  HouseParamsWrapper,
  HouseParamText,
  HouseText,
  ShowInmob,
  HouseParamContainer,
  HouseParamItems,
  ParamIcon,
  ParamIconBet,
  ParamItemsText,
  ParamValueItemsText,
  ParamValueWrapper,
  SgeText,
} from './HouseParams.styled';
import { FixtureSummary } from '#/utils/backend';
import {
  BET_AMOUNT_ICON_BRAND,
  LIQUIDITY_ICON_BRAND,
  NO_OF_BETS_ICON,
} from '#/constants/common/images.constants';

export default function HouseParams({
  addHouseSlip,
  market,
}: {
  market: FixtureSummary;
  addHouseSlip: (market: FixtureSummary) => void;
}) {
  const isMarketDisabled = market.marketStatus !== 'Operational';
  return (
    <div className={HouseParamsContainer}>
      <div className={HouseParamsWrapper}>
        <div className={ShowInmob}>
          <div className="mb-2">
            <div className="flex items-center">
              <span className={HouseParamText}>Market Stats</span>
            </div>
          </div>
          <div className={HouseParamContainer}>
            <div className={HouseParamItems}>
              <div className="flex items-center">
                <span className="mr-2">
                  <img className={ParamIconBet} src={NO_OF_BETS_ICON} alt="" />
                </span>
                <span className={ParamItemsText}>Number of Bets</span>
                <span className={`${ParamValueItemsText} ml-2`}>
                  {market.houseProvision.TotalBets}
                </span>
              </div>
            </div>

            <div className={HouseParamItems}>
              <div className="flex items-center">
                <span className="mr-2">
                  <img
                    className={ParamIcon}
                    src={BET_AMOUNT_ICON_BRAND}
                    alt=""
                  />
                </span>
                <span className={ParamItemsText}>Bet Amount</span>
                <div className={`${ParamValueWrapper} ml-2`}>
                  <span className={ParamValueItemsText}>
                    {market.houseProvision.TotalBetAmount}
                  </span>
                  <span className={SgeText}>SGE</span>
                </div>
              </div>
            </div>
            <div className={HouseParamItems}>
              <div className="flex items-center">
                <span className="mr-2">
                  <img
                    className={ParamIcon}
                    src={LIQUIDITY_ICON_BRAND}
                    alt=""
                  />
                </span>
                <span className={ParamItemsText}>Committed Liquidity</span>
                <div className={`${ParamValueWrapper} ml-2`}>
                  <span className={ParamValueItemsText}>
                    {market.houseProvision.TotalLiquidity}
                  </span>
                  <span className={SgeText}>SGE</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-0">
        <ActionTag
          type="button"
          className={BeTheHouseCTA}
          onClick={() => addHouseSlip(market)}
          id="match_details_be_the_house_click"
          disabled={isMarketDisabled}
        >
          <div
            className={`${HouseText({
              $isMarketSelected: getIsMarketSelected([], market.marketTypeName),
              $isDisabled: isMarketDisabled,
            })} ripple`}
          >
            Be the house
          </div>
        </ActionTag>
      </div>
    </div>
  );
}
