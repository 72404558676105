import { SportEnums, stringToSport } from '#/utils/backend';
import { useParams } from 'react-router-dom';
import SportsFilter from '../SportsFilter';
import Search from '../common/Search/Search.component';
import SearchResults from '../common/Search/SearchResults';
import FixtureOfTheDay from '../FixtureOfTheDay';
import HorizontalMatchList from '../HorizonalMatchList';
import VerticalMatchList from '../VerticalMatchList';
import {
  DEFAULT_MARKET_TYPE,
  SAMPLE_SPORTS_LIST,
} from '#/constants/common/global-constants';
import {
  FEATURED_ICON,
  FOOTBALL_ICON_BRAND,
  TRENDING_ICON,
  CRICKET_ICON_BRAND,
} from '#/constants/common/images.constants';
import { toast } from 'react-toastify';
import ErrorHappened from '../common/ErrorHappened/ErrorHappened.component';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import useHomepageData from '#/utils/dataLoaders/useHomepageData';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import { QueryParams, setSearch } from '#/utils/slices/searchSlice';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '#/hooks/useDebounce';
import { Helmet } from 'react-helmet-async';

export function NoSport(): JSX.Element {
  return (
    <div>
      <ErrorHappened
        label={'Sport not found.'}
        error={new Error('Sport not found.')}
      />
    </div>
  );
}

interface SportDetailWithDataProps {
  sport: SportEnums;
}

const MainWrapper = 'px-4 pb-4 3xl:pb-6 4xl:pb-8';

function SportDetailWithData({ sport }: SportDetailWithDataProps) {
  const { isLoading, error, data } = useHomepageData({ sport });
  const searchInput = useAppSelector((state) => state.search.search);
  const [searchText, setSearchText] = useState(searchInput);
  const debouncedSearchText = useDebounce(searchText, 500);
  const dispatch = useAppDispatch();

  const setSearchInput = useCallback(
    (input: string) => {
      dispatch(setSearch(input));
    },
    [dispatch],
  );

  useEffect(() => {
    setSearchInput(debouncedSearchText);
  }, [debouncedSearchText, setSearchInput]);

  if (error) {
    toast.error(<AppToast id={'UnableToFetch'} />, { icon: false });
  }

  const sportTitle = sport.replace('Soccer', 'Football');

  const searchQuery: QueryParams = {
    search: searchInput,
    offset: 0,
    filters: {
      marketType: DEFAULT_MARKET_TYPE,
      leagueIds: [],
    },
    sport: sport,
    leagues: [],
  };
  return (
    <div>
      <Helmet>
        <title>{`${sportTitle} | SixSigmaSports`}</title>
      </Helmet>
      <div className={MainWrapper}>
        <SportsFilter sportlist={SAMPLE_SPORTS_LIST} selectedSport={sport} />
      </div>
      <div className={MainWrapper}>
        <Search
          search={searchText}
          setSearch={setSearchText}
          showSearchResults={true}
        />
      </div>
      {searchInput?.length ? (
        <div className="px-4">
          <SearchResults searchQuery={searchQuery} />
        </div>
      ) : (
        <div id="marketoftheday">
          <FixtureOfTheDay
            data={data}
            isLoading={isLoading || error}
            id={'marketOfTheDay'}
          />
          <HorizontalMatchList
            data={data}
            isLoading={isLoading || error}
            id={'trending'}
            icon={TRENDING_ICON}
            title={'trending'}
          />
          <HorizontalMatchList
            data={data}
            isLoading={isLoading || error}
            id={'featured'}
            icon={FEATURED_ICON}
            title={'featured'}
          />
          <div className="px-4 pb-4">
            <VerticalMatchList
              icon={
                sport.toLowerCase() === 'soccer'
                  ? FOOTBALL_ICON_BRAND
                  : CRICKET_ICON_BRAND
              }
              data={data}
              isLoading={isLoading || error}
              id={'today'}
              header={`Today's Matches`}
            />
          </div>
        </div>
      )}
    </div>
  );
}

// Sanitizes the sport id before rendering the inner component
export default function SportHome(): JSX.Element {
  const params = useParams();

  const sport = params.sportId && stringToSport(params.sportId);

  if (sport) {
    return <SportDetailWithData sport={sport} />;
  } else {
    return <NoSport />;
  }
}
