import { useQuery } from '@tanstack/react-query';
import env from '../env';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import { CampaignDetails, RewardBucket } from '../backend';
import { useAppDispatch } from '../store';
import {
  setBetCapUsed,
  setCampaignsApplicable,
  setRewardBuckets,
  setTotalRewardsAvailable,
} from '../slices/rewardsSlice';
import useUserData from '#/hooks/useUserData';

function useRewardsDataLoader(refetchInterval?: number) {
  const { cosmWasmClient: client } = useCosmosKitWallet();
  const dispatch = useAppDispatch();
  const { hashedUserId: userId, user } = useUserData();

  return useQuery({
    queryKey: ['rewardsLoader', user?.auth0Id],
    enabled: !!userId && !!client,
    queryFn: async () => {
      if (userId && client) {
        const result: RewardBucket[] = await client.queryContractSmart(
          env.contract,
          {
            locked_tokens: { user_id: userId, start_after: null, limit: 30 },
          },
        );
        const campaigns: CampaignDetails[] = await client.queryContractSmart(
          env.contract,
          {
            campaigns: { start_after: null, limit: 30, descending: true },
          },
        );
        const activeCampaigns = campaigns.filter(
          (item) => item.state === 'active',
        );
        const campaignIdList = activeCampaigns.map((item) => item.id);

        const betCapUsedList: number[] = await client.queryContractSmart(
          env.contract,
          {
            campaign_participation: { user_id: userId, ids: campaignIdList },
          },
        );

        const totalRewards = result
          .map((item) => +item.amount)
          .reduce((partialSum, a) => partialSum + a, 0);

        dispatch(setTotalRewardsAvailable(totalRewards));
        dispatch(setRewardBuckets(result));
        dispatch(setCampaignsApplicable(activeCampaigns));
        dispatch(
          setBetCapUsed(
            betCapUsedList.length && activeCampaigns.length
              ? activeCampaigns[0].cap - betCapUsedList[0]
              : 0,
          ),
        );

        return {
          balance: totalRewards,
        };
      }
    },
    refetchInterval: refetchInterval ? refetchInterval : false, // Polling based on interval, disabled by default
  });
}

export default useRewardsDataLoader;
