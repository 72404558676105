import env from '../utils/env';

const csp = {
  defaultSrc: [
    "'self'",
    'https://verify.synaps.io',
    'https://static.zdassets.com',
    'https://ekr.zdassets.com',
    'https://ekr.zendesk.com',
    'https://*.zopim.com',
    'https://zendesk-eu.my.sentry.io',
    'wss://*.zopim.com',
    'https://saagelabslimited.zendesk.com',
    'wss://saagelabslimited.zendesk.com',
    'https://api.smooch.io',
    'https://media.smooch.io',
    'https://*.twilio.com',
    'wss://api.smooch.io',
    'wss://voice-js.roaming.twilio.com',
    'https://fonts.googleapis.com',
    'https://verify.walletconnect.org',
  ],
  imgSrc: [
    "'self'",
    'https://*.gravatar.com',
    'https://*.wp.com',
    'https://v2assets.zopim.io',
    'https://static.zdassets.com',
    'data:',
    'https://accounts.zendesk.com',
    'https://saagelabslimited.zendesk.com',
    'https://media.smooch.io',
    'https://*.zdusercontent.com',
    'https://bf2028e4-3018-4fef-a511-ddb17fa0f60f.snippet.anjouangaming.org',
    'blob:',
  ],
  scriptSrc: [
    "'self'",
    env.apiServer,
    env.auth0.domain,
    ...(env.chain?.apis?.rest?.map((rest) => rest.address) ?? []),
    ...(env.chain?.apis?.rpc?.map((rpc) => rpc.address) ?? []),
    'https://browser.sentry-cdn.com',
    'https://js.sentry-cdn.com',
    'https://static.zdassets.com',
    'https://ekr.zdassets.com',
    'https://ekr.zendesk.com',
    'https://*.zopim.com',
    'https://bf2028e4-3018-4fef-a511-ddb17fa0f60f.snippet.anjouangaming.org',
    'https://www.googletagmanager.com',
    'https://www.google-analytics.com',
  ],
  styleSrc: ["'self'", "'unsafe-inline'", 'https://fonts.googleapis.com'],
  connectSrc: [
    "'self'",
    'https://rpc.cosmos.directory',
    env.apiServer,
    env.auth0.domain,
    ...(env.chain?.apis?.rest?.map((rest) => rest.address) ?? []),
    ...(env.chain?.apis?.rpc?.map((rpc) => rpc.address) ?? []),
    '*.sentry.io',
    'https://explorer-api.walletconnect.com',
    'https://static.zdassets.com',
    'https://ekr.zdassets.com',
    'https://ekr.zendesk.com',
    'https://*.zopim.com',
    'wss://*.zopim.com',
    'https://saagelabslimited.zendesk.com',
    'https://api.smooch.io',
    'https://media.smooch.io',
    'https://*.twilio.com',
    'wss://api.smooch.io',
    'wss://voice-js.roaming.twilio.com',
    'wss://relay.walletconnect.org',
  ],
};

export default csp;
