/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useAppDispatch, useAppSelector } from '#/utils/store';
import SlipsDrawer from './SlipsDrawer.component';
import {
  AmountText,
  ButtonAccept,
  ButtonReject,
  CardInnerContainer,
  CheckInput,
  CheckLabel,
  ClearButton,
  CloseIcon,
  ContainerScroll,
  ErrorWrapper,
  FlexCenter,
  FlexWrapper,
  InnerContainer,
  InputField,
  OddInfoSubText,
  OddInfoText,
  OddsWrapper,
  PlaceBetBtn,
  PlaceHouseBtn,
  Separator,
  SgeText,
  SlipsSeparator,
  StyleWeb,
  TeamNameText,
  TextError,
  TextOdds,
  TotalAmountText,
} from './style';
import * as betSlipsEvents from '#/utils/slices/betSlipsSlice';
import * as houseSlipsEvents from '#/utils/slices/houseSlipsSlice';
import { useState, useEffect } from 'react';
import { Big } from 'bigdecimal.js';
import { stringToBigdecimal } from '#/utils/bigdecimal';
import Button from '#/components/common/Button';
import { CLOSE_ICON } from '#/constants/common/images.constants';
import { PartialExecuteInstruction, useExecute } from '#/hooks/useExecute';
import useUserData from '#/hooks/useUserData';
import { useKycStatus } from './Components/useKycStatus';
import useOutcomesReloader from '#/utils/dataLoaders/useOutcomesReloader';
import { toast } from 'react-toastify';
import { getMarketNameById, getOutcomeName } from '#/utils/marketsMap';
import {
  checkRewardEarningEligibility,
  convertToSge,
  getUnlockRequest,
  typedOddValue,
} from '#/utils/sgeUtils/sgeUtils';
import ApprovePopup from './Components/ApprovePopup/ApprovePopup.component';
import useBalance from '#/hooks/useBalance';
import { setIsModalOpen } from '#/utils/slices/walletConnectionSlice';
import useBetsDataLoader from '#/utils/dataLoaders/useBetsDataLoader';
import useHouseProvisionsDataLoader from '#/utils/dataLoaders/useHouseProvisionsDataLoader';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import extractIdsFromResult, {
  formatToDecimalPlaces,
} from './SlipsDrawer.utils';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import { RewardBucket, SportEnums, stringToSport } from '#/utils/backend';
import useRewardsDataLoader from '#/utils/dataLoaders/useRewardBalance';
import useHomepageData from '#/utils/dataLoaders/useHomepageData';
import { useParams } from 'react-router-dom';
import { useSearchLoader } from '#/utils/dataLoaders/useSearchLoader';
import useFixtureData from '#/utils/dataLoaders/useFixtureData';
import { useQueryClient } from '@tanstack/react-query';
import { MIN_BET_SLIP_AMOUNT, MIN_HOUSE_SLIP_AMOUNT } from '#/constants/common/global-constants';

interface ErrorProps {
  children: React.ReactNode;
}

function Error({ children }: ErrorProps): JSX.Element {
  return (
    <div className={ErrorWrapper}>
      <span className={TextError}>{children}</span>
    </div>
  );
}

export default function SlipsDrawers(): JSX.Element {
  const betSlips = useAppSelector((state) => state.betSlips);
  const houseSlips = useAppSelector((state) => state.houseSlips);
  const outcomes = useAppSelector((state) => state.outcomes.outcomes);
  const rewardCalculation = useAppSelector((state) => state.rewardsCalculation);
  const { executeMultiple } = useExecute();
  const { account } = useCosmosKitWallet();
  const { data: balance } = useBalance();
  const betSlipItems = Object.values(betSlips);
  const houseSlipItems = Object.values(houseSlips);
  const [betSlipsAccepted, setBetSlipsAccepted] = useState(false);
  const [houseSlipsAccepted, setHouseSlipsAccepted] = useState(false);
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBetPolling, setIsBetPolling] = useState(false);
  const [isHousePolling, setIsHousePolling] = useState(false);
  const { data: betsData, refetch: refetchBets } = useBetsDataLoader(
    isBetPolling ? 3000 : undefined,
  ); // Poll every 3 seconds only when isPolling is true
  const { refetch: refetchRewards } = useRewardsDataLoader(
    isBetPolling ? 3000 : undefined,
  ); // Poll every 3 seconds only when isBetPolling is true

  const { data: houseProvisionsData, refetch: refetchHouseProvisions } =
    useHouseProvisionsDataLoader(isHousePolling ? 3000 : undefined); // Poll every 3 seconds only when isPolling is true
  // Poll every 3 seconds only when isPolling is true
  const [betIds, setBetIds] = useState<string[]>([]); // Track placed bet IDs
  const [houseSlipIds, setHouseSlipIds] = useState<string[]>([]); // Track placed house liquidity provision IDs
  const [toastShown, setToastShown] = useState(false);
  const balanceSge =
    stringToBigdecimal(convertToSge(balance?.amount.toString() || '0')) ??
    Big('0');
  const dispatch = useAppDispatch();
  const { isAuthenticated, user } = useUserData();
  const accountDeactivated = user?.responsibleGambling.accountDeactivated;
  const { kycToken, isLoading: isKycLoading } = useKycStatus();
  const [totalRewardsUsed, setTotalRewardsUsed] = useState(0);

  const params = useParams();
  const sport =
    ((params.sportId && stringToSport(params.sportId)) as SportEnums) ??
    ('Soccer' as SportEnums);
  const isOnHomePage = !!params.sportId;
  const isOnExplorePage = window.location.href.includes('explore');
  const searchQuery = useAppSelector((state) => state.search);
  const { fixtureId } = useParams();
  const isOnFixtureDetails = !!fixtureId;
  const { refetch: refetchFixtureDetails } = useFixtureData({
    id: fixtureId ?? '',
    enabled: isOnFixtureDetails,
  });
  const { refetch: refetchExplorePage } = useSearchLoader({
    query: searchQuery,
    enabled: isOnExplorePage,
  });
  const { refetch: refetchHomeData } = useHomepageData({
    sport,
    enabled: isOnHomePage,
  });

  const queryClient = useQueryClient();

  // Check for bet state changes and batch toast notifications
  useEffect(() => {
    if (betsData && betsData.length > 0 && betIds.length > 0) {
      let allBetsResolved = true; // Track if all bets have reached a final state
      const activeBets: string[] = [];
      const failedBets: string[] = [];

      // Check for each betId if the bet has reached a terminal state
      betIds.forEach((betId) => {
        const newBet = betsData.find((bet) => bet.id === betId); // Match by bet ID

        if (newBet) {
          if (newBet.state === 'Active') {
            activeBets.push(newBet.id); // Collect "Active" bets
          } else if (newBet.state === 'Failed') {
            failedBets.push(newBet.id); // Collect "Failed" bets
          } else {
            allBetsResolved = false; // If any bet is not in terminal state, keep polling
          }
        } else {
          allBetsResolved = false; // Bet not found, keep polling
        }
      });

      // If all bets are resolved, send a single notification
      if (allBetsResolved) {
        // Send batch notifications for all bets that were resolved
        if (activeBets.length > 0) {
          queryClient.invalidateQueries({ queryKey: ['fixture', fixtureId] });

          setTimeout(() => {
            refetchFixtureDetails();
          }, 10000); // Delay to ensure the fixture details are updated

          toast.success(
            <AppToast
              id={activeBets.length === 1 ? 'BetPlaced' : 'BetsPlaced'}
            />,
            { icon: false },
          );
        }

        if (failedBets.length > 0) {
          setTimeout(
            () =>
              toast.error(
                <AppToast
                  id={failedBets.length === 1 ? 'BetFailed' : 'BetsFailed'}
                />,
                { icon: false },
              ),
            1000,
          );
        }
        if (isOnHomePage) {
          refetchHomeData();
        }
        if (isOnExplorePage) {
          refetchExplorePage();
        }

        // Stop polling and reset states
        setIsBetPolling(false);
        setBetIds([]); // Clear betIds for next set of bets
      }
    }
  }, [
    betsData,
    betIds,
    refetchHomeData,
    isOnExplorePage,
    refetchExplorePage,
    isOnHomePage,
    isOnFixtureDetails,
    refetchFixtureDetails,
    queryClient,
    fixtureId,
  ]);

  useEffect(() => {
    if (
      houseProvisionsData &&
      houseProvisionsData.length > 0 &&
      houseSlipIds.length > 0
    ) {
      let allHouseSlipsResolved = true; // Track if all house slips have reached a final state
      const activeHouseSlips = [];
      const failedHouseSlips = [];

      // Check for each houseSlipId if the house slip has reached a terminal state
      houseSlipIds.forEach((houseSlipId) => {
        const newHouseSlip = houseProvisionsData.find(
          (houseSlip) => houseSlip.marketId.toString() === houseSlipId,
        ); // Match by house slip ID

        if (newHouseSlip) {
          if (newHouseSlip.state === 'Active') {
            activeHouseSlips.push(newHouseSlip.marketId); // Collect "Active" house slips
          } else if (newHouseSlip.state === 'Failed') {
            failedHouseSlips.push(newHouseSlip.marketId); // Collect "Failed" house slips
          } else {
            allHouseSlipsResolved = false; // If any house slip is not in terminal state, keep polling
          }
        } else {
          allHouseSlipsResolved = false; // House slip not found, keep polling
        }
      });

      // If all house slips are resolved, send a single notification
      if (allHouseSlipsResolved) {
        // Send batch notifications for all house slips that were resolved
        if (activeHouseSlips.length > 0) {
          if (fixtureId) {
            queryClient.invalidateQueries({ queryKey: ['fixture', fixtureId] });

            setTimeout(() => {
              refetchFixtureDetails();
            }, 10000); // Delay to ensure the fixture details are updated
          }

          toast.success(
            <AppToast
              id={
                activeHouseSlips.length === 1
                  ? 'ProvisionPlaced'
                  : 'ProvisionsPlaced'
              }
            />,
            { icon: false },
          );
        }

        if (failedHouseSlips.length > 0) {
          setTimeout(
            () =>
              toast.error(
                <AppToast
                  id={
                    failedHouseSlips.length === 1
                      ? 'ProvisionFailed'
                      : 'ProvisionsFailed'
                  }
                />,
                { icon: false },
              ),
            1000,
          );
        }
        if (isOnHomePage) {
          refetchHomeData();
        }
        if (isOnExplorePage) {
          refetchExplorePage();
        }

        if (isOnFixtureDetails) {
          refetchFixtureDetails();
        }
        // Stop polling and reset states
        setIsHousePolling(false);
        setHouseSlipIds([]); // Clear houseSlipIds for next set of house slips
      }
    }
  }, [
    fixtureId,
    houseProvisionsData,
    houseSlipIds,
    isOnExplorePage,
    isOnFixtureDetails,
    isOnHomePage,
    queryClient,
    refetchExplorePage,
    refetchFixtureDetails,
    refetchHomeData
  ]);

  useEffect(() => {
    if (
      (betSlipItems.length > 0 || houseSlipItems.length > 0) &&
      accountDeactivated &&
      !toastShown
    ) {
      toast.error(
        'Your account is deactivated. You cannot place bets/deposits.',
      );
      setToastShown(true);
    }

    if (
      accountDeactivated &&
      betSlipItems.length === 0 &&
      houseSlipItems.length === 0
    ) {
      setToastShown(false);
    }
  }, [betSlipItems, houseSlipItems, accountDeactivated, toastShown]);

  useEffect(() => {
    const usedRewards = betSlipItems
      .map((item) => item.rewardAmount)
      .reduce((partialSum, a) => partialSum + a, 0);
    setTotalRewardsUsed(usedRewards);
  }, [betSlipItems]);

  useOutcomesReloader({
    outcomeIds: Object.keys(betSlips),
    marketIds: Object.values(betSlips).map((betSlip) => betSlip.slip.market.id),
  });

  const minimumBetSlipAmount = Big(MIN_BET_SLIP_AMOUNT);
  const minimumHouseSlipAmount = Big(MIN_HOUSE_SLIP_AMOUNT);

  const totalBetSlipAmount = betSlipItems.reduce(
    (acc, val) => acc.add(stringToBigdecimal(val.amount) || Big('0')),
    Big('0'),
  );

  const isWalletConnected = !!account;

  const isPlacingBets =
    isWalletConnected &&
    !accountDeactivated &&
    isAuthenticated &&
    !!kycToken &&
    !isKycLoading;

  const anyBetSlipsErrors = betSlipItems.some((item) => {
    const outcomeValue = outcomes[item.slip.outcome.id]?.value;
    return (
      item.amount === '0' ||
      (stringToBigdecimal(item.amount) || Big('0')).lowerThan(
        minimumBetSlipAmount,
      ) ||
      stringToBigdecimal(item.amount)?.greaterThan(
        item.slip.betProvisions.acceptableBetSize,
      ) || stringToBigdecimal(item.slip.betProvisions.acceptableBetSize)?.lowerThan(minimumBetSlipAmount) ||
      item.slip.outcome.acceptedRate !== outcomeValue
    );
  });

  const isBetSlipButtonDisabled =
    accountDeactivated ||
    !isPlacingBets ||
    anyBetSlipsErrors ||
    totalBetSlipAmount.greaterThan(
      balanceSge.add(totalRewardsUsed) ?? Big('0'),
    );

  const anyHouseSlipsErrors = houseSlipItems.some(
    (item) =>
      item.amount === '0' ||
      (stringToBigdecimal(item.amount) || Big('0')).lowerThan(
        minimumHouseSlipAmount,
      ) ||
      stringToBigdecimal(item.amount)?.greaterThan(
        item.slip.houseProvisions.acceptableProvisionSize,
      ),
  );

  const betetSlipsTotals = betSlipItems.reduce(
    (acc, betSlipItem) => {
      const amount = stringToBigdecimal(betSlipItem.amount) || Big('0');
      const rate =
        stringToBigdecimal(betSlipItem.slip.outcome.acceptedRate) || Big('0');

      return {
        total: acc.total.add(amount),
        returns: acc.returns.add(amount.multiply(rate)),
      };
    },
    {
      total: Big('0'),
      returns: Big('0'),
    },
  );

  const totalHouseSlipsDespositAmount = houseSlipItems.reduce(
    (acc, houseSlipItem) => {
      const amount = stringToBigdecimal(houseSlipItem.amount) || Big('0');

      return acc.add(amount);
    },
    Big('0'),
  );

  const isHouseSlipButtonDisabled =
    accountDeactivated ||
    !isPlacingBets ||
    anyHouseSlipsErrors ||
    !houseSlipsAccepted ||
    totalHouseSlipsDespositAmount.greaterThan(balanceSge);

  const [openIndex, setOpenIndex] = useState(-1);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  const handleProvideLiquidity = async () => {
    if (!isWalletConnected) {
      dispatch(setIsModalOpen(true));
      return;
    }

    setLoading(true);
    const timeout = setTimeout(() => {
      setShowApprovePopup(true);
    }, 1000);
    try {
      const msgs = houseSlipItems.map((houseSlip) => {
        const { slip } = houseSlip;

        const msg: PartialExecuteInstruction = {
          msg: {
            house_provide_liquidity: {
              token: kycToken,
              market_id: String(slip.market.id),
            },
          },
          funds: [
            {
              amount: String(Number(houseSlip.amount) * 10 ** 6),
              denom: 'usge',
            },
          ],
        };

        return msg;
      });

      await executeMultiple(msgs, {
        onSuccess: (result) => {
          const { marketIds: provisionedMarketIds } =
            extractIdsFromResult(result);
          dispatch(houseSlipsEvents.clear());
          setLoading(false);
          setShowApprovePopup(false);
          setIsHousePolling(true);
          refetchHouseProvisions();
          toast.success(
            <AppToast
              id={
                provisionedMarketIds.length === 1
                  ? 'ProcessingHouseSlip'
                  : 'ProcessingHouseSlips'
              }
            />,
            { icon: false },
          );
          if (provisionedMarketIds && provisionedMarketIds.length > 0) {
            setHouseSlipIds((prevHouseSlipIds) => [
              ...prevHouseSlipIds,
              ...provisionedMarketIds,
            ]);
          }
        },
        onError: (err) => {
          if (err.message.includes('Gambling limits exceeded')) {
            toast.error(
              'Bet limits exceeded. You cannot place bets greater than set bet limits.',
            );
            return;
          }
          setHouseSlipIds([]);
          setIsHousePolling(false);
          throw err;
        },
      });
    } catch (error) {
      clearTimeout(timeout);
      console.error('Error submitting house slip:', error);
      setLoading(false);
      setShowApprovePopup(false);
    }
  };

  const handleSubmitBet = async () => {
    if (!isWalletConnected) {
      dispatch(setIsModalOpen(true));
      return;
    }

    setLoading(true);
    const timeout = setTimeout(() => {
      setShowApprovePopup(true);
    }, 1000);
    try {
      let betCapAvail = rewardCalculation.betCapAvailable || 0;
      let buckets = JSON.parse(
        JSON.stringify(rewardCalculation.rewardBuckets),
      ) as RewardBucket[];
      const msgs = betSlipItems.map((betSlip) => {
        const { slip } = betSlip;

        const rewardEarningEligibility = checkRewardEarningEligibility(
          betSlip,
          betCapAvail,
          rewardCalculation.campaignsApplicable,
        );
        const participations = rewardEarningEligibility
          ? rewardCalculation.campaignsApplicable.map((item) => item.id)
          : [];
        if (participations.length) {
          betCapAvail = betCapAvail - 1;
        }
        const unlockReward = getUnlockRequest(buckets, betSlip.rewardAmount);
        buckets = unlockReward.buckets;

        const slippage = !betSlipsAccepted
          ? {
            slippage: slip.slippageParams?.slippage || '0',
            odds: slip.slippageParams?.odds || slip.outcome.acceptedRate,
          }
          : null;
        const msg: PartialExecuteInstruction = {
          msg: {
            submit_bet: {
              market_id: String(slip.market.id),
              outcome: slip.outcome.position,
              slippage,
              token: kycToken,
              participations: participations,
              unlocks: unlockReward.unlockRequest,
            },
          },
          funds: [
            {
              amount: String(
                (Number(betSlip.amount) - betSlip.rewardAmount) * 10 ** 6,
              ),
              denom: 'usge',
            },
          ],
        };

        return msg;
      });

      await executeMultiple(msgs, {
        onSuccess: (result) => {
          const { betIds } = extractIdsFromResult(result);
          dispatch(betSlipsEvents.clear());
          setLoading(false);
          setShowApprovePopup(false);
          setIsBetPolling(true); // Start polling after placing bet
          refetchBets();
          refetchRewards();
          toast.success(
            <AppToast
              id={betIds.length === 1 ? 'ProcessingBet' : 'ProcessingBets'}
            />,
            { icon: false },
          );
          if (betIds && betIds.length > 0) {
            setBetIds((prevBetIds) => [...prevBetIds, ...betIds]); // Store new bet IDs
          }
        },
        onError: (err) => {
          if (err.message.includes('Gambling limits exceeded')) {
            toast.error(
              'Bet limits exceeded. You cannot place bets greater than set bet limits.',
            );
            return;
          }
          setBetIds([]);
          setIsBetPolling(false);
          throw err;
        },
      });
    } catch (error) {
      clearTimeout(timeout);
      console.error('Error submitting bet:', error);
      setLoading(false);
      setShowApprovePopup(false);
    }
  };

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(async () => {
        // Poll for new odds
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [loading]);

  const getButtonContent = (suffix: string) => {
    if (accountDeactivated) {
      return 'Account Deactivated';
    }

    if (loading || isKycLoading) {
      return (
        <>
          <div className="flex items-center mr-4">
            <div className="PageLoader !w-4 !h-4"></div>
          </div>
          Loading...
        </>
      );
    }

    if (!isAuthenticated) {
      return `Login to ${suffix}`;
    }

    if (!isWalletConnected) {
      return `Connect Wallet to ${suffix}`;
    }

    if (!kycToken) {
      return 'KYC Required';
    }

    return suffix;
  };

  return (
    <div
      className={`${CardInnerContainer} ${openIndex !== -1 ? '!-bottom-0.5 lg:!bottom-0' : ''}`}
    >
      {betSlipItems.length > 0 && (
        <>
          <div className={StyleWeb}>
            {showApprovePopup ? (
              <ApprovePopup isOpen={true} onClose={() => null} />
            ) : (
              <>
                <SlipsDrawer
                  index={0}
                  isCollapsed={openIndex === 0}
                  onToggle={handleToggle}
                  count={betSlipItems.length}
                  heading={'Bet Slip'}
                >
                  <div className={InnerContainer}>
                    <div className="text-right mb-4">
                      <Button
                        className={ClearButton}
                        onClick={() => {
                          dispatch(betSlipsEvents.clear());
                        }}
                      >
                        Remove All
                      </Button>
                    </div>
                    <div className={Separator} />
                    <div className={`${ContainerScroll} ContainerScrollNone`}>
                      {betSlipItems.map((betSlip) => {
                        const amount =
                          stringToBigdecimal(betSlip.amount) || Big('0');
                        const currentRate =
                          outcomes[betSlip.slip.outcome.id]?.value;
                        const { slip } = betSlip;
                        return (
                          <div key={betSlip.slip.outcome.id}>
                            <div className={`${FlexWrapper} mb-4`}>
                              <h2 className={OddInfoText}>
                                {' '}
                                {`${getOutcomeName(betSlip.slip.market.marketTypeId, betSlip.slip.outcome.position, betSlip.slip.homeTeam.name, betSlip.slip.awayTeam.name)} @ ${typedOddValue(user?.oddsFormat ?? 'decimal', Number(betSlip.slip.outcome.acceptedRate))}`}
                              </h2>
                              <Button
                                className={ClearButton}
                                onClick={() => {
                                  dispatch(
                                    betSlipsEvents.selectOrDeselect({
                                      slip: betSlip.slip,
                                      campaigns:
                                        rewardCalculation.campaignsApplicable,
                                      totalRewardsAvailable:
                                        rewardCalculation.totalRewardsAvailable,
                                    }),
                                  );
                                }}
                              >
                                <img
                                  className={CloseIcon}
                                  src={CLOSE_ICON}
                                  alt="Close Icon"
                                />
                              </Button>
                            </div>
                            <div className={`${FlexWrapper} mb-4 flex-wrap`}>
                              <div className="flex-1 pr-2">
                                <h3
                                  className={OddInfoSubText}
                                >{`${getMarketNameById(betSlip.slip.market.marketTypeId) || betSlip.slip.market.name}`}</h3>
                                <p className={TeamNameText}>
                                  {' '}
                                  {`${betSlip.slip.homeTeam.name} v ${betSlip.slip.awayTeam.name}`}
                                </p>
                              </div>
                              <span className="relative">
                                <input
                                  className={InputField}
                                  type="number"
                                  min="0"
                                  value={
                                    betSlip.amount === '0' ? '' : betSlip.amount
                                  }
                                  placeholder="Amount"
                                  onChange={(e) => {
                                    dispatch(
                                      betSlipsEvents.updateAmount({
                                        outcomeId: betSlip.slip.outcome.id,
                                        amount: e.target.value,
                                        totalRewardsAvailable:
                                          rewardCalculation.totalRewardsAvailable,
                                      }),
                                    );
                                  }}
                                  onKeyDown={(
                                    e: React.KeyboardEvent<HTMLInputElement>,
                                  ) => {
                                    if (e.key === 'Enter') {
                                      (e.target as HTMLInputElement).blur();
                                    }
                                  }}
                                />
                                {betSlip.amount === '0'
                                  ? ''
                                  : betSlip.amount !== '0' && (
                                    <span className={SgeText}>SGE</span>
                                  )}
                              </span>
                              <div className="grow w-full mt-2.5">
                                {betSlip.rewardSpendingEligibility &&
                                  rewardCalculation.totalRewardsAvailable > 0 ? (
                                  <span className="flex items-center">
                                    <input
                                      className={CheckInput}
                                      type="checkbox"
                                      id="use_rewards"
                                      defaultChecked={betSlip.usingRewards}
                                      onClick={() => {
                                        dispatch(
                                          betSlipsEvents.updateUsingRewards({
                                            outcomeId: betSlip.slip.outcome.id,
                                            usingRewards: !betSlip.usingRewards,
                                            totalRewardsAvailable:
                                              rewardCalculation.totalRewardsAvailable,
                                          }),
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor="use_rewards"
                                      className={CheckLabel}
                                    >
                                      {`Use up to ${rewardCalculation.totalRewardsAvailable.toFixed(2)} SGE Reward tokens`}
                                    </label>
                                  </span>
                                ) : null}
                              </div>

                              {!accountDeactivated &&
                                isWalletConnected &&
                                amount.lowerThan(minimumBetSlipAmount) && (
                                  <Error>
                                    Minimum amount is{' '}
                                    {minimumBetSlipAmount.toString()} SGE
                                  </Error>
                                )}
                              {!accountDeactivated &&
                                isWalletConnected &&
                                balanceSge
                                  .add(totalRewardsUsed)
                                  .lowerThan(totalBetSlipAmount) && (
                                  <Error>Insufficient wallet balance</Error>
                                )}
                              {!accountDeactivated &&
                                isWalletConnected &&
                                stringToBigdecimal(
                                  betSlip?.amount,
                                )?.greaterThan(
                                  slip?.betProvisions?.acceptableBetSize,
                                ) && (
                                  <Error>{`Max bet amount for this is ${formatToDecimalPlaces(slip?.betProvisions?.acceptableBetSize || '0')} SGE`}</Error>
                                )}
                              {currentRate ? (
                                currentRate ===
                                  betSlip.slip.outcome.acceptedRate ? (
                                  <></>
                                ) : (
                                  <div className={OddsWrapper}>
                                    <Error>
                                      <div className={TextOdds}>
                                        The odds have changed from
                                        <br />{' '}
                                        {
                                          betSlip.slip.outcome.acceptedRate
                                        } to {currentRate}.
                                      </div>
                                      <div className="text-center space-x-4">
                                        <Button
                                          className={ButtonAccept}
                                          onClick={() => {
                                            dispatch(
                                              betSlipsEvents.updateAcceptedRate(
                                                {
                                                  outcomeId:
                                                    betSlip.slip.outcome.id,
                                                  acceptedRate: currentRate,
                                                  campaigns:
                                                    rewardCalculation.campaignsApplicable,
                                                  totalRewardsAvailable:
                                                    rewardCalculation.totalRewardsAvailable,
                                                },
                                              ),
                                            );
                                          }}
                                        >
                                          Accept
                                        </Button>
                                        <Button
                                          className={ButtonReject}
                                          onClick={() => {
                                            dispatch(
                                              betSlipsEvents.selectOrDeselect({
                                                slip: betSlip.slip,
                                                campaigns:
                                                  rewardCalculation.campaignsApplicable,
                                                totalRewardsAvailable:
                                                  rewardCalculation.totalRewardsAvailable,
                                              }),
                                            );
                                          }}
                                        >
                                          Reject
                                        </Button>
                                      </div>
                                    </Error>
                                  </div>
                                )
                              ) : (
                                // this breaks the layout
                                <></>
                              )}
                            </div>

                            <div className={Separator} />
                          </div>
                        );
                      })}
                    </div>
                    <div className="mt-2">
                      <div className={FlexCenter}>
                        <input
                          className={CheckInput}
                          type="checkbox"
                          id="accept-txn-odd-change"
                          checked={betSlipsAccepted}
                          onChange={() => {
                            setBetSlipsAccepted(!betSlipsAccepted);
                          }}
                        />
                        <label
                          htmlFor="accept-txn-odd-change"
                          className={CheckLabel}
                        >
                          Accept all odds and line changes
                        </label>
                      </div>
                    </div>
                    <div className={`${FlexWrapper} mt-3`}>
                      <span className={TotalAmountText}>Total Bet Amount:</span>
                      <span className={AmountText}>
                        <span>{betetSlipsTotals.total.toString()}</span>{' '}
                        <span>SGE</span>{' '}
                      </span>
                    </div>
                    {rewardCalculation.totalRewardsAvailable > 0 ? (
                      <div className={`${FlexWrapper} mt-1`}>
                        <span className={TotalAmountText}>
                          Total Rewards Used:
                        </span>
                        <span className={AmountText}>
                          <span>{totalRewardsUsed}</span> <span>SGE</span>{' '}
                        </span>
                      </div>
                    ) : null}
                    <div className={`${FlexWrapper} mt-1`}>
                      <span className={TotalAmountText}>
                        Total Estimated Return:
                      </span>
                      <span className={AmountText}>
                        <span>{betetSlipsTotals.returns.toString()} </span>{' '}
                        <span>SGE</span>
                      </span>
                    </div>
                    <div className="my-5 flex justify-center">
                      <Button
                        className={PlaceBetBtn({
                          isBetSlipButtonDisabled,
                        })}
                        onClick={handleSubmitBet}
                        disabled={isBetSlipButtonDisabled}
                      >
                        <div className="flex justify-center items-center">
                          {getButtonContent('Place Bet')}
                        </div>
                      </Button>
                    </div>
                  </div>
                </SlipsDrawer>
                <div className={SlipsSeparator} />
              </>
            )}
          </div>
        </>
      )}

      {houseSlipItems.length > 0 && (
        <>
          <div className={StyleWeb}>
            {showApprovePopup ? (
              <ApprovePopup isOpen={true} onClose={() => null} />
            ) : (
              <>
                <SlipsDrawer
                  index={1}
                  isCollapsed={openIndex === 1}
                  onToggle={handleToggle}
                  count={houseSlipItems.length}
                  heading={'House Slip'}
                >
                  <div className={InnerContainer}>
                    <div className="text-right mb-4">
                      <Button
                        className={ClearButton}
                        onClick={() => {
                          dispatch(houseSlipsEvents.clear());
                        }}
                      >
                        Remove All
                      </Button>
                    </div>

                    <div className={Separator} />
                    <div className={`${ContainerScroll} ContainerScrollNone`}>
                      {houseSlipItems.map((houseSlip) => {
                        const amount =
                          stringToBigdecimal(houseSlip.amount) || Big('0');

                        return (
                          <div key={houseSlip.slip.market.id}>
                            <div className={`${FlexWrapper} mb-4`}>
                              <h2 className={OddInfoText}>
                                {' '}
                                {getMarketNameById(
                                  houseSlip.slip.market.marketTypeId,
                                ) || houseSlip.slip.market.name}
                              </h2>
                              <Button
                                className={ClearButton}
                                onClick={() => {
                                  dispatch(
                                    houseSlipsEvents.selectOrDeselect(
                                      houseSlip.slip,
                                    ),
                                  );
                                }}
                              >
                                <img
                                  className={CloseIcon}
                                  src={CLOSE_ICON}
                                  alt="Close Icon"
                                />
                              </Button>
                            </div>
                            <div
                              className={`${FlexWrapper} mb-4 items-center flex-wrap`}
                            >
                              <div className="flex-1 pr-2">
                                <p className={TeamNameText}>
                                  {' '}
                                  {`${houseSlip.slip.homeTeam.name} - ${houseSlip.slip.awayTeam.name}`}
                                </p>
                              </div>
                              <span className="relative">
                                <input
                                  className={InputField}
                                  type="number"
                                  min="0"
                                  value={
                                    houseSlip.amount === '0'
                                      ? ''
                                      : houseSlip.amount
                                  }
                                  placeholder="Amount"
                                  onChange={(e) => {
                                    dispatch(
                                      houseSlipsEvents.updateAmount({
                                        marketId: houseSlip.slip.market.id,
                                        amount: e.target.value,
                                      }),
                                    );
                                  }}
                                />
                                {houseSlip.amount === '0'
                                  ? ''
                                  : houseSlip.amount !== '0' && (
                                    <span className={SgeText}>SGE</span>
                                  )}
                              </span>

                              {!accountDeactivated &&
                                isWalletConnected &&
                                amount.lowerThan(minimumHouseSlipAmount) && (
                                  <Error>
                                    Minimum amount is{' '}
                                    {minimumHouseSlipAmount.toString()} SGE
                                  </Error>
                                )}
                              {!accountDeactivated &&
                                isWalletConnected &&
                                balanceSge.lowerThan(
                                  totalHouseSlipsDespositAmount,
                                ) && <Error>Insufficient wallet balance</Error>}
                              {!accountDeactivated &&
                                isWalletConnected &&
                                stringToBigdecimal(
                                  houseSlip?.amount,
                                )?.greaterThan(
                                  houseSlip.slip.houseProvisions
                                    .acceptableProvisionSize,
                                ) && (
                                  <Error>{`Maximum liquidity on the market is ${formatToDecimalPlaces(houseSlip.slip.houseProvisions.acceptableProvisionSize)} SGE`}</Error>
                                )}
                            </div>
                            <div className={Separator} />
                          </div>
                        );
                      })}
                    </div>
                    <div className={FlexCenter}>
                      <input
                        className={CheckInput}
                        type="checkbox"
                        id="accept-txn-odd-change"
                        checked={houseSlipsAccepted}
                        onChange={() => {
                          setHouseSlipsAccepted(!houseSlipsAccepted);
                        }}
                      />
                      <label
                        htmlFor="accept-txn-odd-change"
                        className={CheckLabel}
                      >
                        Accept all odds and line changes
                      </label>
                    </div>
                    <div className={`${FlexWrapper} mt-3`}>
                      <span className={TotalAmountText}>
                        Total Deposit Amount:
                      </span>
                      <span className={AmountText}>
                        {totalHouseSlipsDespositAmount.toString()} SGE
                      </span>
                    </div>
                    <div className="my-5 flex justify-center">
                      <Button
                        className={PlaceHouseBtn({
                          isHouseSlipButtonDisabled,
                        })}
                        onClick={handleProvideLiquidity}
                        disabled={isHouseSlipButtonDisabled}
                      >
                        <div className="flex justify-center items-center">
                          {getButtonContent('Provide Liquidity')}
                        </div>
                      </Button>
                    </div>
                  </div>
                </SlipsDrawer>
              </>
            )}
            <div className={SlipsSeparator} />
          </div>
        </>
      )}
    </div>
  );
}
