import ActivityCard from '#/components/common/ActivityCard/ActivityCard.component';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';
import { getStatusTypeHouse, HouseProvision } from '#/utils/backend';
import WithData from './Wrapper/WrapWithTransitionStates';
import {
  LoaderBg,
  LoaderCardContainer,
} from './common/LoaderStyle/LoaderStyle.styled';
import EmptyList from './common/EmptyStates/EmptyState.component';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import { getMarketNameById } from '#/utils/marketsMap';
import { toast } from 'react-toastify';
import { setIsModalOpen } from '#/utils/slices/walletConnectionSlice';
import Button from '#/components/common/Button';
import useUserData from '#/hooks/useUserData';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import { useState } from 'react';
import ActionTag from './common/ActionTags.component';
import {
  BeHouseText,
  FlexContainerBeHouse,
  HouseIcon,
} from './MatchCard/MatchCard/MatchCard.styled';
import {
  MINUS_CIRCLE_ICON,
  PLUSH_CIRCLE_ICON,
} from '#/constants/common/images.constants';
import EngagementDetailsCard from './EngagementDetailsCard';
import { CardFlexItem } from './common/ActivityCard/ActivityCard.types';

const Btn = `w-full sm:w-80 tracking-wider rounded-md bg-brand-bg-1 px-4 py-3 text-black font-bold text-sm xxxs:text-basew-full sm:w-80 tracking-wider rounded-md bg-transparent px-4 py-2.5 text-text-brand-1 font-bold text-sm xxxs:text-base text-center border border-border-bg-2 relative`;

function HouseActivityCardsWithData({
  searchInput,
  activeSubItem,
  data,
  withdraw,
}: {
  searchInput: string;
  activeTab: string;
  activeSubItem: string;
  data: HouseProvision[];
  withdraw: (marketId: string, serial: number) => Promise<void>;
}) {
  const activityFilters = useAppSelector((state) => state.activityFilters);
  const { user } = useUserData();
  const { account } = useCosmosKitWallet();
  const [withdrawingMarkets, setWithdrawingMarkets] = useState<Set<string>>(
    new Set(),
  );
  const [showEngagementDetails, setShowEngagementDetails] = useState<
    Set<string>
  >(new Set());
  const accountDeactivated = user?.responsibleGambling?.accountDeactivated;
  const dispatch = useAppDispatch();

  const handleWithdraw = async (provision: HouseProvision) => {
    if (accountDeactivated) {
      toast.error('Your account is deactivated. Withdrawal is disabled.');
      return;
    }

    if (!account) {
      dispatch(setIsModalOpen(true));
      return;
    }

    try {
      setWithdrawingMarkets((prev) => new Set(prev.add(provision.id)));
      // Extract and validate the serial from provision.id (format: marketId-serial)
      const parts = provision.id.split('-');
      if (parts.length !== 2) {
        throw new Error(`Invalid provision ID format: ${provision.id}`);
      }

      const serialStr = parts[1];
      const serial = Number(serialStr);

      if (isNaN(serial)) {
        throw new Error(`Serial is not a valid number: ${serialStr}`);
      }

      await withdraw(provision.marketId.toString(), serial);
      toast.success('Withdrawal successful');
    } catch (error) {
      console.error('Error during withdrawal:', error);
      if (error instanceof Error) {
        if (error.message.includes('User cancelled')) {
          return;
        }
        toast.error(`Failed to withdraw: ${error.message}`);
      } else {
        toast.error('An unexpected error occurred during withdrawal');
      }
    } finally {
      setWithdrawingMarkets((prev) => {
        const newSet = new Set(prev);
        newSet.delete(provision.id);
        return newSet;
      });
    }
  };

  const filteredData = data.filter((provision) => {
    let fitsTheTab;
    switch (activeSubItem) {
      case 'active':
        fitsTheTab = provision.state === 'Active';
        break;
      case 'settled':
        fitsTheTab =
          provision.state === 'Settled' || provision.state === 'Failed';
        break;
      default:
        fitsTheTab = false;
    }

    const fitsSearch =
      searchInput.length > 0
        ? provision.fixtureName
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        : true;

    const allowedMarketTypeIds: number[] = [];
    Object.keys(activityFilters.marketTypeIds).forEach((marketTypeId) => {
      const typedMarketTypeId = parseInt(marketTypeId);
      if (activityFilters.marketTypeIds[typedMarketTypeId]) {
        allowedMarketTypeIds.push(typedMarketTypeId);
      }
    });

    const fitsMarketTypeId =
      allowedMarketTypeIds.length > 0
        ? allowedMarketTypeIds.includes(provision.marketTypeId)
        : true;

    return fitsTheTab && fitsSearch && fitsMarketTypeId;
  });

  if (!filteredData.length) {
    return <EmptyList id="houseDeposits" />;
  }

  return (
    <>
      {filteredData.map((provision) => {
        const statusType = getStatusTypeHouse(provision);
        const formatDecimal = (value: string) => parseFloat(value).toFixed(2);
        const isWithdrawing = withdrawingMarkets.has(provision.id.toString());
        return (
          <ActivityCard
            key={provision.id}
            statusText={''}
            statusType={statusType}
            dateText={formatDateToDesiredFormat(provision.createdAt)}
            title={getMarketNameById(provision.marketTypeId) || ''}
            subTitle={provision.fixtureName}
            transactionLink={provision.txHash}
            infoItems={
              [
                {
                  label: 'Provided: ',
                  value: `${formatDecimal(provision.amount)} SGE`,
                },
                (provision.profitOrLoss &&
                  parseFloat(provision.profitOrLoss) !== 0) ||
                  (provision.state &&
                    provision.state !== 'Active' && {
                      label: 'Win/Loss: ',
                      value: `${formatDecimal(provision.profitOrLoss || '0')} SGE`,
                    }),
                {
                  label: 'Returned: ',
                  value: `${formatDecimal(provision.withdrawnAmount)} SGE`,
                },
                provision.totalBets > 0 && {
                  label: 'Risk Exposure: ',
                  value: `${formatDecimal(provision.riskExposure || '0')} SGE`,
                },
                {
                  label: 'Bet Value: ',
                  value: `${formatDecimal(provision.betValue)} SGE`,
                },
              ].filter(Boolean) as CardFlexItem[]
            }
          >
            {provision.state === 'Active' && (
              <Button
                className={Btn}
                onClick={() => handleWithdraw(provision)}
                disabled={isWithdrawing || provision.withdrawnAmount !== '0'}
              >
                {isWithdrawing
                  ? 'Processing...'
                  : provision.withdrawnAmount !== '0'
                    ? 'Withdrawn'
                    : `Withdraw ${formatDecimal(provision.amount)} SGE`}
              </Button>
            )}
            {provision.totalBets > 0 ? (
              <ActionTag
                ariaExpanded={showEngagementDetails.has(provision.id)}
                type="div"
                id={'show_engagement_details'}
                onClick={() =>
                  setShowEngagementDetails((prev) => {
                    const newSet = new Set(prev);
                    if (newSet.has(provision.id)) {
                      newSet.delete(provision.id);
                    } else {
                      newSet.add(provision.id);
                    }
                    return newSet;
                  })
                }
                className={FlexContainerBeHouse + ' mt-2'}
              >
                <img
                  className={HouseIcon}
                  src={
                    showEngagementDetails.has(provision.id)
                      ? MINUS_CIRCLE_ICON
                      : PLUSH_CIRCLE_ICON
                  }
                  alt={
                    showEngagementDetails.has(provision.id)
                      ? 'Collapse'
                      : 'Expand'
                  }
                />
                <span className={BeHouseText + ' my-2'}>
                  {showEngagementDetails.has(provision.id)
                    ? 'Hide Bets'
                    : `View Bets`}{' '}
                  ({provision.totalBets})
                </span>
              </ActionTag>
            ) : (
              <>
                <div className={FlexContainerBeHouse + ' mt-2 opacity-25'}>
                  <img
                    className={HouseIcon + 'opacity-25 text-gray-500'}
                    src={PLUSH_CIRCLE_ICON}
                    alt={'Expand'}
                  />
                  <div className={`${BeHouseText} text-gray-500 my-2`}>
                    No bets to show
                  </div>
                </div>
              </>
            )}
            {showEngagementDetails.has(provision.id) && (
              <EngagementDetailsCard provision={provision} />
            )}
          </ActivityCard>
        );
      })}
    </>
  );
}

const LoadingComponent = () => {
  return (
    <div className="px-0">
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5  w-11/12 mb-2`}></div>
          <div className="w-full flex items-center mb-2">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className="w-full flex items-center mb-2">
            <div className={`${LoaderBg} h-5 w-4/12 mr-24`}></div>
            <div className={`${LoaderBg} h-5 w-5/12`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-5/12`}></div>
        </div>
      </div>
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5  w-11/12 mb-2`}></div>
          <div className="w-full flex items-center mb-2">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className="w-full flex items-center mb-2">
            <div className={`${LoaderBg} h-5 w-4/12 mr-24`}></div>
            <div className={`${LoaderBg} h-5 w-5/12`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-5/12`}></div>
        </div>
      </div>
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5  w-11/12 mb-2`}></div>
          <div className="w-full flex items-center mb-2">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className="w-full flex items-center mb-2">
            <div className={`${LoaderBg} h-5 w-4/12 mr-24`}></div>
            <div className={`${LoaderBg} h-5 w-5/12`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-5/12`}></div>
        </div>
      </div>
    </div>
  );
};

const HouseActivityCards = WithData<HouseProvision[]>(
  HouseActivityCardsWithData,
  LoadingComponent,
  true,
);

export default HouseActivityCards;
