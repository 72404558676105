import {
  CONNECT_WALLET_IMG,
  KEPLR_ICON,
} from '#/constants/common/images.constants';
import AnimatedModal from '#/components/common/AnimatedModal.component';
import {
  CardContainer,
  HeaderSubTitle,
  HeaderTitle,
  ImgContainer,
  InnerContainer,
  InstallBtn,
  InstallImg,
  RedirectImg,
  StepsNumber,
  StepsWrapper,
  TopBorder,
} from './InstallKeplrPopup.styled';
import Button from '#/components/common/Button';

const InstallKeplrPopup = ({
  isOpen,
  onClick,
  onClose,
}: {
  isOpen: boolean;
  onClick: () => void;
  onClose: () => void;
}) => {
  return (
    <AnimatedModal
      title=""
      isOpen={isOpen}
      onClose={onClose}
      showCloseIcon={false}
    >
      <div className={CardContainer}>
        <div className="text-center">
          <div className={TopBorder} />
        </div>
        <div className={InnerContainer}>
          <div>
            <div className={ImgContainer}>
              <img className={InstallImg} src={KEPLR_ICON} alt="Keplr Logo" />
            </div>
            <div className="text-center">
              <h2 className={HeaderTitle}>Connect Wallet through Keplr</h2>
              <div className={ImgContainer}>
                <img
                  className={RedirectImg}
                  src={CONNECT_WALLET_IMG}
                  alt="Img"
                />
              </div>
              <div className={StepsWrapper}>
                <span className={StepsNumber}>1</span>
                <h3 className={HeaderSubTitle}>
                  Open SixSigmaSports inside Keplr's in-app browser
                </h3>
              </div>
              <div className={StepsWrapper}>
                <span className={StepsNumber}>2</span>
                <h3 className={HeaderSubTitle}>
                  Connect your wallet inside of Keplr app's in-app browser
                </h3>
              </div>
              <div className={StepsWrapper}>
                <span className={StepsNumber}>3</span>
                <h3 className={HeaderSubTitle}>
                  Afterwards, you can return to your browser and connect your
                  wallet regularly
                </h3>
              </div>
              <div className="w-full mb-6">
                <Button className={InstallBtn} onClick={onClick}>
                  Open or install Keplr
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimatedModal>
  );
};
export default InstallKeplrPopup;
