import {
  InnerContainer,
  NavIcon,
  NavIconActive,
  NavItems,
  NavigationContainer,
} from './MobileNavigation.styled';
import Link from '../Link.component';
import {
  EXPLORE_TEXT,
  HOME_TEXT,
  IMG_ALT_TEXT,
  MYBETS_TEXT,
  NOTIFICATIONS_TEXT,
} from './MobileNavigation.constants';
import { useLocation } from 'react-router-dom';
import {
  BETS_ICON,
  BETS_ICON_ACTIVE,
  EXPLORE_ICON,
  EXPLORE_ICON_BRAND,
  HOME_ICON,
  HOME_ICON_BRAND,
  NOTIFICATION_ICON,
  NOTIFICATION_ICON_BRAND,
} from '#/constants/common/images.constants';

type NavItem = {
  iconActive: string;
  to: string;
  in: string[];
  icon: string;
  text: string;
};

const navItems: NavItem[] = [
  {
    to: '/soccer',
    in: ['/soccer', '/cricket'],
    icon: HOME_ICON,
    iconActive: HOME_ICON_BRAND,
    text: HOME_TEXT,
  },
  {
    to: '/explore',
    in: ['/explore', '/explore/soccer', '/explore/cricket'],
    icon: EXPLORE_ICON,
    iconActive: EXPLORE_ICON_BRAND,
    text: EXPLORE_TEXT,
  },
  {
    to: '/my-bets',
    in: ['/my-bets'],
    icon: BETS_ICON,
    iconActive: BETS_ICON_ACTIVE,
    text: MYBETS_TEXT,
  },
  {
    to: '/notifications',
    in: ['/notifications'],
    icon: NOTIFICATION_ICON,
    iconActive: NOTIFICATION_ICON_BRAND,
    text: NOTIFICATIONS_TEXT,
  },
];

export default function MobileNavigation() {
  const { pathname } = useLocation();
  return (
    <div className={NavigationContainer}>
      <div className={InnerContainer}>
        {navItems.map((item, index) => {
          const isActive = item.in?.some(
            (i) => i.toLowerCase() === pathname.toLowerCase(),
          );

          return (
            <Link
              key={index}
              className={NavItems({
                isActive,
              })}
              to={item.to}
            >
              <span>
                {isActive ? (
                  <img
                    className={NavIconActive}
                    src={item.iconActive}
                    alt={IMG_ALT_TEXT}
                  />
                ) : (
                  <img className={NavIcon} src={item.icon} alt={IMG_ALT_TEXT} />
                )}
              </span>
              <span>{item.text}</span>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
