import { OddsFormat } from '#/utils/slices/usersSlice';
import { ConversionList } from './OddsConversion.styled';

export const getOddsFormatName = (oddFormat: OddsFormat): string => {
  switch (oddFormat) {
    case 'decimal':
      return 'Decimal Odds';
    case 'fractional':
      return 'Fractional Odds';
    case 'american':
      return 'Americal Odds';
    default:
      return 'Decimal Odds';
  }
};

export const getOddsConversionContent = (from: string, to: string) => {
  if (from === 'decimal' && to === 'american') {
    return {
      rules: (
        <ul className={ConversionList}>
          <li>- If the decimal odds are 2.00 or higher:</li>
          <li>&nbsp;&nbsp;- American Odds = (Decimal Odds - 1) * 100</li>
          <li>- If the decimal odds are less than 2.00:</li>
          <li>&nbsp;&nbsp;- American Odds = -100 / (Decimal Odds - 1)</li>
        </ul>
      ),
      examples: (
        <ul className={ConversionList}>
          <li>- Decimal odds of 3.00 would be (3.00 - 1) * 100 = +200</li>
          <li>- Decimal odds of 1.50 would be -100 / (1.50 - 1) = -200</li>
        </ul>
      ),
    };
  } else if (from === 'american' && to === 'decimal') {
    return {
      rules: (
        <ul className={ConversionList}>
          <li>- If the American odds are positive:</li>
          <li>&nbsp;&nbsp;- Decimal Odds = (American Odds / 100) + 1</li>
          <li>- If the American odds are negative:</li>
          <li>&nbsp;&nbsp;- Decimal Odds = 1 - (100 / American Odds)</li>
        </ul>
      ),
      examples: (
        <ul className={ConversionList}>
          <li>- American odds of +200 would be (200 / 100) + 1 = 3.00</li>
          <li>- American odds of -200 would be 1 - (100 / 200) = 1.50</li>
        </ul>
      ),
    };
  } else if (from === 'fractional' && to === 'decimal') {
    return {
      rules: (
        <ul className={ConversionList}>
          <li>- Decimal Odds = (Numerator / Denominator) + 1</li>
        </ul>
      ),
      examples: (
        <ul className={ConversionList}>
          <li>- Fractional odds of 3/1 would be (3 / 1) + 1 = 4.00</li>
          <li>- Fractional odds of 1/3 would be (1 / 3) + 1 = 1.33</li>
        </ul>
      ),
    };
  } else if (from === 'decimal' && to === 'fractional') {
    return {
      rules: (
        <ul className={ConversionList}>
          <li>- Subtract 1 from the decimal odds.</li>
          <li>- Convert this number to a fraction.</li>
          <li>- Simplify the fraction to its simplest form.</li>
        </ul>
      ),
      examples: (
        <ul className={ConversionList}>
          <li>
            - Decimal odds of 4.00 would be 4.00 - 1 = 3, which is 3/1 in
            fractional odds.
          </li>
          <li>
            - Decimal odds of 2.50 would be 2.50 - 1 = 1.5, which is 3/2 in
            fractional odds.
          </li>
          <li>
            - Decimal odds of 1.50 would be 1.50 - 1 = 0.5, which is 1/2 in
            fractional odds.
          </li>
          <li>
            - Decimal odds of 1.20 would be 1.20 - 1 = 0.20, which is 1/5 in
            fractional odds.
          </li>
        </ul>
      ),
    };
  } else if (from === 'american' && to === 'fractional') {
    return {
      rules: (
        <ul className={ConversionList}>
          <li>
            - For positive American odds, divide the odds by 100 to get the
            numerator (denominator is 1).
          </li>
          <li>
            - For negative American odds, divide 100 by the absolute value of
            the odds (denominator is the absolute value of the odds).
          </li>
          <li>- Simplify the fraction to its simplest form.</li>
        </ul>
      ),
      examples: (
        <ul className={ConversionList}>
          <li>
            - American odds of +200 would be (200 / 100) = 2/1 in fractional
            odds.
          </li>
          <li>
            - American odds of +150 would be (150 / 100) = 3/2 in fractional
            odds.
          </li>
          <li>
            - American odds of -150 would be (100 / 150) = 2/3 in fractional
            odds.
          </li>
          <li>
            - American odds of -400 would be (100 / 400) = 1/4 in fractional
            odds.
          </li>
        </ul>
      ),
    };
  } else if (from === 'fractional' && to === 'american') {
    return {
      rules: (
        <ul className={ConversionList}>
          <li>
            - If the fractional odds are greater than 1/1, multiply the
            fractional odds by 100.
          </li>
          <li>
            - If the fractional odds are less than 1/1, divide -100 by the
            fractional odds.
          </li>
          <li>- Simplify the result to get the American odds.</li>
        </ul>
      ),
      examples: (
        <ul className={ConversionList}>
          <li>
            - Fractional odds of 2/1 would convert to +200 in American odds.
          </li>
          <li>
            - Fractional odds of 5/2 would convert to +250 in American odds.
          </li>
          <li>
            - Fractional odds of 1/2 would convert to -200 in American odds.
          </li>
          <li>
            - Fractional odds of 1/4 would convert to -400 in American odds.
          </li>
        </ul>
      ),
    };
  }
  return {
    rules: <div>No rules available</div>,
    examples: <div>No examples available</div>,
  };
};
