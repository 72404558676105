import Link from '../common/Link.component';
import RedirectLink from '../common/RedirectLink.component';
import {
  EIGHTEEN_PLUS_LOGO,
  SSS_LOGO,
} from '#/constants/common/images.constants';
import {
  FooterHeader,
  PageTitle,
  FooterLogo,
  FooterContainer,
  SubTitle,
  RequirmentBtnWrapper,
  RequirmentBtn,
  RequirmentLogo,
  FooterLinks,
  Separator,
  SocialIconWrapper,
  SocialIcon,
  DiscordIcon,
} from './Footer.styled';
import {
  BETTING_RULES_URL,
  BETTING_TEXT,
  CONTACT_TEXT,
  COOKIES_TEXT,
  DOCS_TEXT,
  DOCS_URL,
  FAQS_URL,
  FAQ_TEXT,
  JOIN_COMMUNITY,
  PRIVACY_POLICY,
  PRIVACY_POLICY_URL,
  RESPONSIBLE_GAMBLING,
  SocialLinksData,
  TERMS_TEXT,
  TERMS_URL,
  USEFUL_LINK,
  footerText,
} from './Footer.constants';
import AnjSeal from './AnjSeal';

export default function Footer() {
  const socialLinks = SocialLinksData.filter(
    (socialLink) => !socialLink.isButton,
  );
  return (
    <div className={FooterContainer}>
      <div className={FooterHeader}>
        <span>
          <img
            className={FooterLogo}
            src={SSS_LOGO}
            alt="Six Sigma Sports Logo"
          />
        </span>
        <h2 className={PageTitle}>SixSigmaSports</h2>
      </div>
      <h3 className={SubTitle}>The future of betting.</h3>
      <div className={RequirmentBtnWrapper}>
        <span className={RequirmentBtn}>
          <span>
            <img
              className={RequirmentLogo}
              src={EIGHTEEN_PLUS_LOGO}
              alt="18+ Requirement Logo"
            />
          </span>
          <span>18+ Requirement</span>
        </span>
        <span className={RequirmentBtn}>
          <AnjSeal />
        </span>
      </div>
      <div className="flex">
        <div className="flex-1">
          <h2 className={PageTitle}>{USEFUL_LINK}</h2>
          <div className="mt-4 4xl:mt-6">
            <button
              className={FooterLinks}
              onClick={() => {
                window.zE && window.zE.activate({ hideOnClose: true });
              }}
              rel="noreferrer"
            >
              {CONTACT_TEXT}
            </button>
            <RedirectLink
              className={FooterLinks}
              href={DOCS_URL}
              target="_blank"
              rel="noreferrer"
            >
              {DOCS_TEXT}
            </RedirectLink>
            <RedirectLink
              className={FooterLinks}
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noreferrer"
            >
              {PRIVACY_POLICY}
            </RedirectLink>
            <Link className={FooterLinks} to={'/responsible-gambling'}>
              {RESPONSIBLE_GAMBLING}
            </Link>
            <RedirectLink
              className={FooterLinks}
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noreferrer"
            >
              {COOKIES_TEXT}
            </RedirectLink>
            <RedirectLink
              className={FooterLinks}
              href={TERMS_URL}
              target="_blank"
              rel="noreferrer"
            >
              {TERMS_TEXT}
            </RedirectLink>
            <RedirectLink
              className={FooterLinks}
              href={FAQS_URL}
              target="_blank"
              rel="noreferrer"
            >
              {FAQ_TEXT}
            </RedirectLink>
            <RedirectLink
              className={FooterLinks}
              href={BETTING_RULES_URL}
              target="_blank"
              rel="noreferrer"
            >
              {BETTING_TEXT}
            </RedirectLink>
          </div>
        </div>
        <div className="flex-1">
          <h2 className={PageTitle}>{JOIN_COMMUNITY}</h2>
          <div className={SocialIconWrapper}>
            {socialLinks.map((item) => (
              <RedirectLink
                href={item.href}
                target="_blank"
                rel="noreferrer"
                key={item.dark_image}
                id={`footer_${item.label}_link`}
              >
                <img
                  className={`${SocialIcon} ${item.label === 'discord' ? DiscordIcon : ''}`}
                  loading="lazy"
                  src={item.dark_image}
                  id={`social-links-${item.label}`}
                  alt={`${item.label} Social Icon`}
                />
              </RedirectLink>
            ))}
          </div>
        </div>
      </div>
      <div className={Separator} />
      <h3 className={SubTitle}>{footerText(new Date().getFullYear())}</h3>
    </div>
  );
}
