import './window.d.ts';

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
  createRoutesFromChildren,
  matchRoutes,
  useNavigationType,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import NavBar from './components/NavBar/NavBar.component';
import Explore from './components/routes/Explore';
import './styles/tailwind.css';
import '@interchain-ui/react/styles';
import SportHome from './components/routes/SportHome';
import MyBets from './components/routes/MyBets';
import { SportEnums } from './utils/backend';
import { ReactNode, useEffect } from 'react';
import * as localStorageUtils from '#/utils/localStorage';
import { decodeBetSlips, decodeHouseSlips } from './utils/Slip';
import { set as setBetSlips } from '#/utils/slices/betSlipsSlice';
import { set as setHouseSlips } from '#/utils/slices/houseSlipsSlice';
import store, { useAppDispatch, useAppSelector } from '#/utils/store';
import { Provider } from 'react-redux';
import SlipsDrawers from './components/SlipsDrawers/SlipsDrawers.component';
import MobileNavigation from './components/common/MobileNavigation/MobileNavigation.component';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notifications from './components/routes/Notifications';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import env from '#/utils/env';
import GetStarted from './components/OnboardingComponent/GetStarted/GetStarted.component';
import WalletDeposit from './components/routes/Deposit';
import Footer from './components/Footer/Footer.component';
import useUserData from './hooks/useUserData';
import FixtureDetails from './components/routes/FixtureDetails';
import TransactionsList from './components/TransactionsList';
import ResponsibleGambling from './components/ResponsibleGambling/ResponsibleGambling.component';
import * as Sentry from '@sentry/react';
import useSentryUser from './hooks/useSentryUser';
import {
  configItems,
  logo,
  menuItems,
} from './components/NavBar/NavBar.constants';
import SideMenu from './components/NavBar/SideMenuPanel/SideMenu.component';
import {
  ForDesktopLeftPanel,
  RightContainer,
} from './components/NavBar/SideMenuPanel/SideMenu.styled';
import EditProfile from './components/EditProfile/EditProfile.component';
import { useTimeLimitNotification } from './hooks/useTimeLimitNotification';
import useCheckUserTimeout from './hooks/useCheckUserTimeout';
import TimeOutStates from './components/ResponsibleGambling/Components/TimeOutStates.component';
import useCosmosKitManager from './hooks/useCosmosKitManager';
import useCosmosKitWallet from './hooks/useCosmosKitWallet';
import OddsConversion from './components/OddsConversion/OddsConversion.component.js';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import csp from './utils/csp';

if (env.sentry) {
  Sentry.init({
    normalizeDepth: 10,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserProfilingIntegration(),
    ],
    tracePropagationTargets: [
      'localhost',
      'https://staging.sixsigmasports.app',
      'https://testnet.sixsigmasports.app',
      'https://sixsigmasports.app',
    ],
    ...env.sentry,
  });
}

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    } else {
      const id = document.getElementById(hash.substring(1));
      if (id) {
        id.scrollIntoView();
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [hash, pathname]);

  return null;
}

function Layout({ children }: { children: ReactNode }) {
  const { isAuthenticated, user } = useUserData();
  const { logout } = useAuth0();
  const shouldShowGetStarted =
    isAuthenticated && (!user?.sgeAddress || user?.kycStatus !== 'APPROVED');
  useTimeLimitNotification();
  const timeOutData = user?.responsibleGambling.timeOut ?? null;

  const isTimedOut = useCheckUserTimeout();

  if (isTimedOut) {
    return (
      <TimeOutStates
        appliedAt={timeOutData?.appliedAt ?? null}
        duration={timeOutData?.duration ?? null}
      />
    );
  }

  return (
    <>
      <ScrollToTop />
      <div className="bg-primary-bg-1 font-Helvetica h-fit xl:flex xl:flex-wrap">
        <div className={ForDesktopLeftPanel}>
          <SideMenu
            menuItems={menuItems}
            configItems={configItems}
            user={user}
            isAuthenticated={isAuthenticated}
            logo={logo}
            logout={logout}
          />
        </div>
        <div className={RightContainer}>
          <NavBar />
          {shouldShowGetStarted && <GetStarted />}
          {children}
          <SlipsDrawers />
          <Footer />
        </div>
      </div>
      <MobileNavigation />
      <ToastContainer
        toastClassName={
          'w-full xl:w-1/3 top-14 left-0 items-center \nshadow-toast-shadow-1'
        }
        toastStyle={{
          backgroundColor: '#222232',
          borderRadius: '0.375rem',
          boxShadow:
            'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)',
          padding: '1rem',
        }}
        limit={1}
        style={{ width: '97%', paddingLeft: '1rem' }}
        hideProgressBar={true}
        closeButton={false}
      />
    </>
  );
}

const defaultSport: SportEnums = 'Soccer';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Navigate to={`/${defaultSport.toLowerCase()}`} replace />,
  },
  {
    path: '/:sportId',
    element: (
      <Layout>
        <SportHome />
      </Layout>
    ),
  },
  {
    path: '/explore',
    element: (
      <Layout>
        <Explore />
      </Layout>
    ),
  },
  {
    path: '/explore/:sportId',
    element: (
      <Layout>
        <Explore />
      </Layout>
    ),
  },
  {
    path: '/my-bets',
    element: (
      <Layout>
        <MyBets />
      </Layout>
    ),
  },
  {
    path: '/match-detail/:fixtureId',
    element: (
      <Layout>
        <FixtureDetails />
      </Layout>
    ),
  },
  {
    path: '/deposit',
    element: (
      <Layout>
        <WalletDeposit />
      </Layout>
    ),
  },
  {
    path: '/notifications',
    element: (
      <Layout>
        <Notifications />
      </Layout>
    ),
  },
  {
    path: '/transactions',
    element: (
      <Layout>
        <TransactionsList />
      </Layout>
    ),
  },
  {
    path: '/responsible-gambling',
    element: (
      <Layout>
        <ResponsibleGambling />
      </Layout>
    ),
  },
  {
    path: '/edit-profile',
    element: (
      <Layout>
        <EditProfile />
      </Layout>
    ),
  },
  {
    path: '/odds-conversion',
    element: (
      <Layout>
        <OddsConversion />
      </Layout>
    ),
  },
]);

function Loaders(): JSX.Element {
  useCosmosKitManager();

  const { account, disconnect } = useCosmosKitWallet();

  useSentryUser();

  const linkedAddress = useAppSelector((state) => state.users.sgeAddress);

  useEffect(() => {
    if (account && linkedAddress) {
      if (account.address !== linkedAddress) {
        toast.warning(`Please connect to ${linkedAddress}`);
        disconnect();
      }
    }
  }, [account, disconnect, linkedAddress]);

  return <></>;
}

function AppInner(): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const betSlipsFromStorage = localStorageUtils.getBetSlips();
    const houseSlipsFromStorage = localStorageUtils.getHouseSlips();

    if (betSlipsFromStorage) {
      const decodedSlips = decodeBetSlips(JSON.parse(betSlipsFromStorage));

      if (decodedSlips) {
        dispatch(setBetSlips(decodedSlips));
      }
    }

    if (houseSlipsFromStorage) {
      const decodedSlips = decodeHouseSlips(JSON.parse(houseSlipsFromStorage));

      if (decodedSlips) {
        dispatch(setHouseSlips(decodedSlips));
      }
    }
  }, []);

  return (
    <>
      <Loaders />
      <RouterProvider router={router} />
    </>
  );
}

export default function App(): JSX.Element {
  const auth0 = env.auth0;

  return (
    <div
      className={`bet-mode bg-primary-bg-1 font-Helvetica min-h-screen relative pb-20 xl:pb-4`}
      id="app-container"
    >
      <HelmetProvider>
        <Helmet>
          <meta
            httpEquiv="Content-Security-Policy"
            content={`default-src ${csp.defaultSrc.join(' ')}; img-src ${csp.imgSrc.join(' ')}; script-src ${csp.scriptSrc.join(' ')}; style-src ${csp.styleSrc.join(' ')}; connect-src ${csp.connectSrc.join(' ')};`}
          />
        </Helmet>
        <div className="bg-primary-bg-1 font-Helvetica h-fit">
          <QueryClientProvider client={new QueryClient()}>
            <Provider store={store}>
              <Auth0Provider
                domain={auth0.domain}
                clientId={auth0.clientId}
                authorizationParams={{
                  redirect_uri: auth0.redirectUri,
                  audience: auth0.audience,
                }}
                cacheLocation="localstorage"
                useRefreshTokens
              >
                <AppInner />
              </Auth0Provider>
            </Provider>
            <ReactQueryDevtools buttonPosition="bottom-right" />
          </QueryClientProvider>
        </div>
        <div id="portal-root"></div>
      </HelmetProvider>
    </div>
  );
}
