import { CampaignDetails, RewardBucket } from '../backend';
import { BetSlip, BetSlips, BetSlipWithAmount } from '../Slip';
import { OddsFormat } from '../slices/usersSlice';

/** Converts SGE to USGE */
export function convertToUsge(sgeValue: string) {
  return Math.round(Number(sgeValue) * 10 ** 6);
}

/** Convert currency from usge denom to sge */
export function convertToSge(usgeValue: string, addCurrency?: boolean) {
  if (Number.isNaN(Number(usgeValue))) return '';
  return addCurrency
    ? `${+usgeValue / 10 ** 6} SGE`
    : `${+usgeValue / 10 ** 6}`;
}

export function convertToSgeWithCurrency(usgeValue: string) {
  if (Number.isNaN(Number(usgeValue))) return '';
  return `${convertToSge(usgeValue)} SGE`;
}

export function checkRewardSpendingEligibility(
  slip: BetSlip,
  campaigns: CampaignDetails[],
) {
  if (!campaigns.length) {
    return false;
  }
  const criterias = campaigns[0].ty.bet_bonus.eligibility;
  if (!criterias) {
    return true;
  }
  if (
    (criterias.odds?.min && +slip.outcome.acceptedRate < criterias.odds.min) ||
    (criterias.odds?.max && +slip.outcome.acceptedRate > criterias.odds.max) ||
    (criterias.market_types.length > 0 &&
      criterias.market_types.includes(slip.market.marketTypeId)) ||
    (criterias.fixtures.length > 0 &&
      criterias.fixtures.includes(+slip.fixture.id)) ||
    (criterias.leagues.length > 0 &&
      criterias.leagues.includes(slip.league.id)) ||
    (criterias.sports.length > 0 && criterias.sports.includes(slip.sport.id))
  ) {
    return false;
  }
  return true;
}

export function checkRewardEarningEligibility(
  slip: BetSlipWithAmount,
  capAvailable: number,
  campaigns: CampaignDetails[],
) {
  if (!campaigns.length || campaigns[0].amount === '0') {
    return false;
  }
  const criterias = campaigns[0].eligibility_criteria;
  const betSize = campaigns[0].ty.bet_bonus.bet_size;
  if (!criterias && !betSize && capAvailable > 0) {
    return true;
  }
  if (
    (criterias.odds?.min &&
      +slip.slip.outcome.acceptedRate < criterias.odds.min) ||
    (criterias.odds?.max &&
      +slip.slip.outcome.acceptedRate > criterias.odds.max) ||
    (criterias.market_types.length > 0 &&
      !criterias.market_types.includes(slip.slip.market.marketTypeId)) ||
    (criterias.fixtures.length > 0 &&
      !criterias.fixtures.includes(+slip.slip.fixture.id)) ||
    (criterias.leagues.length > 0 &&
      !criterias.leagues.includes(slip.slip.league.id)) ||
    (criterias.sports.length > 0 &&
      !criterias.sports.includes(slip.slip.sport.id))
  ) {
    return false;
  }

  if (capAvailable === 0) {
    return false;
  }

  if (betSize?.min && +slip.amount < +betSize.min) {
    return false;
  }
  return true;
}

export function redistributeRewards(slips: BetSlips, totalRewards: number) {
  const betSlips = Object.keys(slips).map((key) => {
    return { key, value: slips[key] };
  });
  let availableTokens = totalRewards;
  betSlips.forEach((item) => {
    if (
      item.value.usingRewards &&
      item.value.rewardSpendingEligibility &&
      availableTokens > 0
    ) {
      if (+item.value.amount > availableTokens) {
        item.value.rewardAmount = availableTokens;
      } else {
        item.value.rewardAmount = +item.value.amount;
      }
    } else {
      item.value.rewardAmount = 0;
    }
    availableTokens = availableTokens - item.value.rewardAmount;
  });

  return betSlips.map((item) => {
    return {
      outcomeId: item.key,
      rewardAmount: item.value.rewardAmount,
    };
  });
}

export function getUnlockRequest(
  rewardBuckets: RewardBucket[],
  rewardAmount: number,
) {
  type UnlockRequest = {
    amount: string;
    participation: {
      campaign_id: string;
      serial: number;
    };
  };

  const buckets = JSON.parse(JSON.stringify(rewardBuckets)) as RewardBucket[];
  const unlockRequest: UnlockRequest[] = [];
  let rewardAmountCalculated = rewardAmount;

  buckets.forEach((bucket) => {
    if (+bucket.amount === 0 || rewardAmountCalculated === 0) {
      return;
    }
    if (+bucket.amount >= rewardAmountCalculated) {
      unlockRequest.push({
        amount: String(rewardAmountCalculated),
        participation: {
          campaign_id: bucket.campaign_id,
          serial: bucket.serial,
        },
      });
      bucket.amount = (+bucket.amount - rewardAmountCalculated).toString();
      rewardAmountCalculated = 0;
    } else {
      unlockRequest.push({
        amount: bucket.amount,
        participation: {
          campaign_id: bucket.campaign_id,
          serial: bucket.serial,
        },
      });
      rewardAmountCalculated = rewardAmountCalculated - +bucket.amount;
      bucket.amount = '0';
    }
  });

  return {
    unlockRequest,
    buckets,
  };
}
function convertDecimalToFractional(decimal: number): string {
  // If the decimal is an integer, return it as numerator/1
  if (Number.isInteger(decimal)) {
    return `${decimal}/1`;
  }

  // Convert decimal to string and determine the number of decimal places
  const decimalStr = decimal.toString();
  const decimalPlaces = decimalStr.includes('.')
    ? decimalStr.split('.')[1].length
    : 0;

  // Scale the decimal to an integer
  const denominator = Math.pow(10, decimalPlaces);
  const numerator = decimal * denominator;

  // Reduce the fraction by GCD
  const gcdValue = gcd(Math.round(numerator), denominator);
  const reducedNumerator = Math.round(numerator) / gcdValue;
  const reducedDenominator = denominator / gcdValue;

  return `${reducedNumerator}/${reducedDenominator}`;
}

// Helper function to calculate GCD (Greatest Common Divisor)
function gcd(a: number, b: number): number {
  return b ? gcd(b, a % b) : Math.abs(a);
}

export function convertDecimalToAmerican(decimal: number) {
  if (decimal >= 2) {
    return Math.floor((decimal - 1) * 100);
  }
  if (decimal > 1) {
    return Math.floor(-100 / (decimal - 1));
  }
  return 0;
}

export function typedOddValue(type: OddsFormat, value: number) {
  if (type === 'american') {
    return convertDecimalToAmerican(value);
  }
  if (type === 'fractional') {
    return convertDecimalToFractional(value);
  }
  return value.toString();
}
