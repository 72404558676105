import { useState } from 'react';
import { GREEN_INFO_ICON } from '#/constants/common/images.constants';
import {
  FieldContainer,
  InputLabel,
  InputField,
  PageTitle,
  PageSubTitle,
  PageContainer,
  OddsDisplayMsg,
  InfoIcon,
  OddsConversionTitle,
  OddsTitle,
  OddsInputField,
  OddsConversionContainer,
} from './OddsConversion.styled';
import usePostSettingsDataLoader from '#/utils/dataLoaders/usePostSettingsUserData';
import { toast } from 'react-toastify';
import useUserData from '#/hooks/useUserData';
import { OddsFormat } from '#/utils/slices/usersSlice';
import { Helmet } from 'react-helmet-async';
import {
  getOddsConversionContent,
  getOddsFormatName,
} from './OddsConversion.utils';

const OddConversionOptions = ['decimal', 'fractional', 'american'];

export default function OddsConversion() {
  const { mutate: postSettings } = usePostSettingsDataLoader();
  const { user, setUser } = useUserData();
  const [fromOdds, setFromOdds] = useState('decimal');
  const [toOdds, setToOdds] = useState('american');

  const handleOddsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (OddConversionOptions.includes(e.target.value)) {
      postSettings(
        { oddsFormat: e.target.value as OddsFormat },
        {
          onSuccess: (data) => {
            setUser({ oddsFormat: data.oddsFormat });
            toast.success('Odds Updated!');
          },
          onError: () => {
            toast.error('Failed to update odds.');
          },
        },
      );
    }
  };

  const { rules, examples } = getOddsConversionContent(fromOdds, toOdds);
  const filteredToOptions = OddConversionOptions.filter(
    (option) => option !== fromOdds,
  );
  const filteredFromOptions = OddConversionOptions.filter(
    (option) => option !== toOdds,
  );

  return (
    <div className={PageContainer}>
      <Helmet>
        <title>{`Odds Conversion | SixSigmaSports`}</title>
      </Helmet>
      <h2 className={`${PageTitle} mb-4`}>Odds Conversion</h2>
      <div className={OddsDisplayMsg}>
        <span className="flex-none">
          <img className={InfoIcon} src={GREEN_INFO_ICON} alt="Icon" />
        </span>
        <span>
          American and Fractional odds are for display purposes only. All bets
          are executed with decimal odds.
        </span>
      </div>
      <div className="mt-5">
        <div className={FieldContainer}>
          <label htmlFor="from" className={InputLabel}>
            From
          </label>
          <select
            id="from"
            value={fromOdds}
            className={InputField}
            onChange={(e) => setFromOdds(e.target.value)}
          >
            {filteredFromOptions.map((option) => (
              <option key={option} value={option}>
                {getOddsFormatName(option as OddsFormat)}
              </option>
            ))}
          </select>
        </div>
        <div className={FieldContainer}>
          <label htmlFor="to" className={InputLabel}>
            To
          </label>
          <select
            id="to"
            value={toOdds}
            className={InputField}
            onChange={(e) => setToOdds(e.target.value)}
          >
            {filteredToOptions.map((option) => (
              <option key={option} value={option}>
                {getOddsFormatName(option as OddsFormat)}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <h3 className={PageSubTitle}>Rules</h3>
        {rules}
      </div>
      <div>
        <h3 className={PageSubTitle}>Examples</h3>
        {examples}
      </div>

      <div className={OddsConversionContainer}>
        <h1 className={OddsConversionTitle}>Change Displayed Odds</h1>
        <span className={OddsTitle}>
          Odds:
          <select
            id="odds-format"
            value={user?.oddsFormat}
            className={OddsInputField}
            onChange={handleOddsChange}
          >
            {OddConversionOptions.map((option) => (
              <option key={option} value={option}>
                {getOddsFormatName(option as OddsFormat)}
              </option>
            ))}
          </select>
        </span>
      </div>
    </div>
  );
}
