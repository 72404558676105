import { configureStore, combineReducers } from '@reduxjs/toolkit';
import betSlipsReducer from './slices/betSlipsSlice';
import houseSlipsReducer from './slices/houseSlipsSlice';
import outcomesReducer from './slices/outcomesSlice';
import usersReducer from './slices/usersSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import searchReducer from './slices/searchSlice';
import activityFiltersReducer from './slices/activityFiltersSlice';
import cosmosKitManagerReducer from './slices/cosmosKitManagerSlice';
import responsibleGamblingReducer from './slices/responsibleGamblingSlice';
import walletConnectionReducer from './slices/walletConnectionSlice';
import rewardsCalculationReducer from './slices/rewardsSlice';
import * as Sentry from '@sentry/react';

const reducer = combineReducers({
  cosmosKitManager: cosmosKitManagerReducer,
  betSlips: betSlipsReducer,
  houseSlips: houseSlipsReducer,
  outcomes: outcomesReducer,
  users: usersReducer,
  search: searchReducer,
  responsibleGambling: responsibleGamblingReducer,
  activityFilters: activityFiltersReducer,
  walletConnection: walletConnectionReducer,
  rewardsCalculation: rewardsCalculationReducer,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export type RootState = ReturnType<typeof reducer>;

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['chainClients.setQueryClient'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload'],
        // Ignore these paths in the state
        ignoredPaths: ['chainClients.queryClient'],
      },
    }),
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
