import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SetURLSearchParams } from 'react-router-dom';
import { ActivityOutcome, SportEnumValues } from '../backend';

export const SPORT_URL_PARAM = 'sport';
export const OUTCOME_URL_PARAM = 'outcome';
export const MARKET_TYPE_ID_URL_PARAM = 'market';

// Define the initial state type
export type State = {
  search: string;
  outcomes: Record<ActivityOutcome, boolean>;
  sports: Record<SportEnumValues, boolean>;
  marketTypeIds: Record<number, boolean>;
};

// Define the initial state
const initialState: State = {
  search: '',
  outcomes: {
    Won: false,
    Lost: false,
    NoResult: false,
  },
  sports: {
    [SportEnumValues.Soccer]: false,
    [SportEnumValues.Cricket]: false,
  },
  marketTypeIds: {},
};

// Helper function to sync filters to URL parameters
const syncUrlParams = (
  filterType: string,
  filters: Record<string, boolean>,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
) => {
  searchParams.delete(filterType);
  Object.keys(filters).forEach((filter) => {
    if (filters[filter]) {
      searchParams.append(filterType, filter);
    }
  });
  setSearchParams(searchParams);
};

// Redux slice for managing filters
const slice = createSlice({
  name: 'activityFilters',
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },

    // Set multiple outcome filters
    setOutcomeFilters(state, action: PayloadAction<ActivityOutcome[]>) {
      Object.keys(state.outcomes).forEach((key) => {
        state.outcomes[key as ActivityOutcome] = false;
      });
      action.payload.forEach((outcome) => {
        state.outcomes[outcome] = true;
      });
    },

    // Toggle a specific outcome filter and sync URL
    toggleOutcomeFilterAndSynchUrl: (
      state,
      action: PayloadAction<{
        outcome: ActivityOutcome[];
        searchParams: URLSearchParams;
        setSearchParams: SetURLSearchParams;
      }>,
    ) => {
      // Reset and set selected outcomes to true
      Object.keys(state.outcomes).forEach((key) => {
        state.outcomes[key as ActivityOutcome] = false;
      });
      action.payload.outcome.forEach((outcome) => {
        state.outcomes[outcome] = true;
      });

      // Sync URL params
      syncUrlParams(
        OUTCOME_URL_PARAM,
        state.outcomes,
        action.payload.searchParams,
        action.payload.setSearchParams,
      );
    },

    // Set multiple sport filters
    setSportFilters(state, action: PayloadAction<SportEnumValues[]>) {
      Object.keys(state.sports).forEach((key) => {
        state.sports[key as SportEnumValues] = false;
      });
      action.payload.forEach((sport) => {
        state.sports[sport] = true;
      });
    },

    // Toggle a specific sport filter and sync URL
    toggleSportFilterAndSynchUrl(
      state,
      action: PayloadAction<{
        sport: SportEnumValues[];
        searchParams: URLSearchParams;
        setSearchParams: SetURLSearchParams;
      }>,
    ) {
      // Reset and set selected sports to true
      Object.keys(state.sports).forEach((key) => {
        state.sports[key as SportEnumValues] = false;
      });
      action.payload.sport.forEach((sport) => {
        state.sports[sport] = true;
      });

      // Sync URL params
      syncUrlParams(
        SPORT_URL_PARAM,
        state.sports,
        action.payload.searchParams,
        action.payload.setSearchParams,
      );
    },

    // Set multiple market type filters
    setMarketTypeIdFilter(state, action: PayloadAction<number[]>) {
      state.marketTypeIds = {};
      action.payload.forEach((marketTypeId) => {
        state.marketTypeIds[marketTypeId] = true;
      });
    },

    // Toggle a specific market type filter and sync URL
    toggleMarketTypeIdFilterAndSynchUrl(
      state,
      action: PayloadAction<{
        marketTypeIds: number[];
        searchParams: URLSearchParams;
        setSearchParams: SetURLSearchParams;
      }>,
    ) {
      // Reset and set selected market types to true
      state.marketTypeIds = {};
      action.payload.marketTypeIds.forEach((marketTypeId) => {
        state.marketTypeIds[marketTypeId] = true;
      });

      // Sync URL params
      syncUrlParams(
        MARKET_TYPE_ID_URL_PARAM,
        state.marketTypeIds,
        action.payload.searchParams,
        action.payload.setSearchParams,
      );
    },

    // Clear all filters and sync URL
    clearActivityFilters(
      state,
      action: PayloadAction<{
        searchParams: URLSearchParams;
        setSearchParams: SetURLSearchParams;
      }>,
    ) {
      state.sports = initialState.sports;
      state.outcomes = initialState.outcomes;
      state.marketTypeIds = initialState.marketTypeIds;

      const { searchParams, setSearchParams } = action.payload;
      syncUrlParams(
        SPORT_URL_PARAM,
        state.sports,
        searchParams,
        setSearchParams,
      );
      syncUrlParams(
        OUTCOME_URL_PARAM,
        state.outcomes,
        searchParams,
        setSearchParams,
      );
      syncUrlParams(
        MARKET_TYPE_ID_URL_PARAM,
        state.marketTypeIds,
        searchParams,
        setSearchParams,
      );
    },
  },
});

export const {
  setSearch,
  setOutcomeFilters,
  toggleOutcomeFilterAndSynchUrl,
  setSportFilters,
  toggleSportFilterAndSynchUrl,
  setMarketTypeIdFilter,
  toggleMarketTypeIdFilterAndSynchUrl,
  clearActivityFilters,
} = slice.actions;

export default slice.reducer;
