import {
  FixtureDetailsMobile,
  FixtureDetailsWrapper,
  FixtureInfoContainer,
  FixtureInfoWrapper,
  MobTournamentDate,
  FixtureLeagueName,
  FixtureTeamName,
  IconsWrapper,
  IconsSize,
  ShareBtn,
  TeamNameWrapper,
  FixtureTeam,
} from './MatchDetails.styled';
import { Fixture } from '#/utils/backend';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';
import WithData from '../Wrapper/WrapWithTransitionStates';
import { LoaderBg } from '../common/LoaderStyle/LoaderStyle.styled';
import Button from '../common/Button';
import {
  DOWNARROW_BRAND_ICON,
  SHARE_CIRCLE_ICON,
  STAR_CIRCLE_ICON,
  STAR_CIRCLE_ICON_SELECTED,
} from '#/constants/common/images.constants';
import { toast } from 'react-toastify';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import { useFavouritesLoader } from '#/utils/dataLoaders/useFavouritesLoader';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

interface MatchDetailsHeaderProps {
  data: Fixture;
}

function MatchDetailsHeaderWithData({ data }: MatchDetailsHeaderProps) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const {
    favouritesIds,
    addFavourite,
    removeFavourite,
    isLoading: isFavouritesLoading,
  } = useFavouritesLoader();
  const isFavourite = favouritesIds.includes(data.id);

  function share() {
    if (!navigator.share) return;

    navigator
      .share({
        url: window.location.href,
        title: `SixSigmaSports - ${data.teams.Home.Name} vs ${data.teams.Away.Name}`,
        text: '',
      })
      .then(() => {
        toast.success(<AppToast id="MatchShareSuccess" />, { icon: false });
      })
      .catch(() => {
        toast.error(<AppToast id="MatchShareFailed" />, { icon: false });
      });
  }

  function handleFavouriteClick() {
    if (!isAuthenticated) {
      // Prompt user to log in?
      loginWithRedirect();
      return;
    }
    try {
      if (isFavourite) {
        // Remove from favourites
        removeFavourite.mutate(data.id);
      } else {
        // Add to favourites
        addFavourite.mutate(data.id);
      }
    } catch (error) {
      console.error('Error handling favourite action', error);
    }
  }
  const navigate = useNavigate();
  return (
    <div className={FixtureInfoContainer}>
      <div className={FixtureInfoWrapper}>
        <div className='hidden xl:flex mb-5'>
          <Button onClick={() => navigate(-1)}>
            <img
              className='w-4 4xl:w-6 rotate-90'
              src={DOWNARROW_BRAND_ICON}
              alt="Back Icon"
            /></Button>
          <div className='flex ml-2'>
            <span className={FixtureTeam}>{data.teams.Home.Name}</span>
            <span className={FixtureTeam}>&nbsp;-&nbsp;</span>
            <span className={FixtureTeam}>{data.teams.Away.Name}</span>
          </div>
        </div>
        <div className={FixtureDetailsWrapper}>
          <div className={FixtureDetailsMobile}>
            <div className={IconsWrapper}>
              <Button className={ShareBtn} onClick={share}>
                <img
                  className={IconsSize}
                  src={SHARE_CIRCLE_ICON}
                  alt="Share Icon"
                />
              </Button>
              <Button
                onClick={handleFavouriteClick}
                disabled={isFavouritesLoading}
              >
                <img
                  className={`${IconsSize} ${isFavourite ? 'hover:invert-0' : ''
                    } ${isFavouritesLoading ? 'animate-pulse' : ''}`}
                  src={
                    isFavourite ? STAR_CIRCLE_ICON_SELECTED : STAR_CIRCLE_ICON
                  }
                  alt="Favorites Icon"
                />
              </Button>
            </div>
            <div className={TeamNameWrapper}>
              <div className={FixtureTeamName}>{data.teams.Home.Name}</div>
              <div className={FixtureTeamName}>{data.teams.Away.Name}</div>
            </div>
            <div className={MobTournamentDate}>
              {formatDateToDesiredFormat(data.startDate)}
            </div>
            <div>
              <div className={FixtureLeagueName}>{data.league.Name}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const LoadingComponent = () => {
  return (
    <div className="flex flex-row justify-between bg-primary-bg-2 relative p-4">
      <div className="w-full">
        <div className="w-full flex justify-end space-x-4">
          <div className={`${LoaderBg} size-8 !rounded-full`}></div>
          <div className={`${LoaderBg} size-8 !rounded-full`}></div>
        </div>
        <div className="w-full mt-14">
          <div className={`${LoaderBg} h-6 w-3/5 mb-2`}></div>
          <div className={`${LoaderBg} h-6 w-2/4 mb-2`}></div>
        </div>
        <div className={MobTournamentDate}>
          <div className={`${LoaderBg} h-5 w-1/3 mb-2`}></div>
          <div className={`${LoaderBg} h-5 w-1/2`}></div>
        </div>
      </div>
    </div>
  );
};

const MatchDetailsHeader = WithData<Fixture>(
  MatchDetailsHeaderWithData,
  LoadingComponent,
  true,
);

export default MatchDetailsHeader;
