/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  CheckInput,
  FilterCardContainer,
  HeadDivider,
  HeaderWrapper,
  MarketItemContainer,
  MarketLabel,
  Title,
} from '#/components/FilterCards/FilterCards.styled';
import { ActivityOutcome } from '#/utils/backend';

const outcomeOptions: ActivityOutcome[] = ['Won', 'Lost'];

const OutcomeFilter = ({
  localOutcomeFilters,
  setLocalOutcomeFilters,
}: {
  localOutcomeFilters: Record<ActivityOutcome, boolean>;
  setLocalOutcomeFilters: (filters: Record<ActivityOutcome, boolean>) => void;
}) => {
  const handleOutcomeChange = (outcome: ActivityOutcome) => {
    setLocalOutcomeFilters({
      ...localOutcomeFilters,
      [outcome]: !localOutcomeFilters[outcome],
    });
  };

  return (
    <>
      <div className={HeaderWrapper}>
        <span className={Title}>Outcome</span>
      </div>
      <hr className={HeadDivider} />
      <div className={FilterCardContainer}>
        <ul>
          {outcomeOptions.map((outcome) => (
            <li
              className={MarketItemContainer}
              key={outcome}
              onClick={() => handleOutcomeChange(outcome)}
            >
              <input
                className={`${CheckInput} !mr-0`}
                type="checkbox"
                id={outcome}
                checked={localOutcomeFilters[outcome] || false}
                onChange={() => {}} // No need to handle change directly here
              />
              <span className={MarketLabel}>{outcome}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default OutcomeFilter;
