import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import useUserData from './useUserData';

/**
 * Hook to check if the user is currently timed out based on the timeout duration.
 * The user will remain timed out until the current time exceeds the timeout expiry.
 */
const useCheckUserTimeout = () => {
  const [isTimedOut, setIsTimedOut] = useState(false);
  const { user } = useUserData();

  useEffect(() => {
    const checkTimeout = () => {
      if (
        user?.responsibleGambling.timeOut?.appliedAt &&
        user?.responsibleGambling.timeOut.duration
      ) {
        const appliedAtTime = Math.floor(
          dayjs(user?.responsibleGambling.timeOut?.appliedAt).valueOf() / 1000,
        ); // Convert to seconds
        const timeoutExpiry =
          appliedAtTime + user?.responsibleGambling.timeOut.duration; // Add duration (in seconds) to appliedAt

        const currentTime = Math.floor(dayjs().valueOf() / 1000); // Current time in seconds

        // If the current time is still less than the timeout expiry, the user is timed out
        if (currentTime < timeoutExpiry) {
          setIsTimedOut(true);
        } else {
          setIsTimedOut(false);
        }
      } else {
        setIsTimedOut(false); // No timeout set, so user is not timed out
      }
    };

    // Check timeout immediately when the component mounts
    checkTimeout();

    // Set up an interval to recheck every minute
    const interval = setInterval(checkTimeout, 60 * 1000); // 1 minute interval

    return () => clearInterval(interval); // Clean up interval on unmount
  }, [user]);

  return isTimedOut;
};

export default useCheckUserTimeout;
