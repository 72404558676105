import { SportEnums, loadHomepageData, outcomeId } from '#/utils/backend';
import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '../store';
import { Outcomes, updateOutcomes } from '../slices/outcomesSlice';

const useHomepageData = ({
  sport,
  enabled = true,
}: {
  sport: SportEnums;
  enabled?: boolean;
}) => {
  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: ['homepage', sport],
    queryFn: async () => {
      return loadHomepageData(sport).then((homepageData) => {
        const newOutcomes: Outcomes = {};

        Object.values(homepageData.marketOfTheDay?.odds ?? {}).forEach(
          (odds) => {
            newOutcomes[outcomeId(odds)] = {
              value: odds.value,
              movement: odds.movement,
              acceptable_bet_size: odds.acceptableBetSize,
            };
          },
        );

        homepageData.featured.forEach((featured) => {
          Object.values(featured.odds).forEach((odds) => {
            newOutcomes[outcomeId(odds)] = {
              value: odds.value,
              movement: odds.movement,
              acceptable_bet_size: odds.acceptableBetSize,
            };
          });
        });

        homepageData.trending.forEach((trending) => {
          Object.values(trending.odds).forEach((odds) => {
            newOutcomes[outcomeId(odds)] = {
              value: odds.value,
              movement: odds.movement,
              acceptable_bet_size: odds.acceptableBetSize,
            };
          });
        });

        homepageData.today.forEach((today) => {
          Object.values(today.odds).forEach((odds) => {
            newOutcomes[outcomeId(odds)] = {
              value: odds.value,
              movement: odds.movement,
              acceptable_bet_size: odds.acceptableBetSize,
            };
          });
        });

        dispatch(updateOutcomes(newOutcomes));

        return homepageData;
      });
    },
    enabled,
  });
};

export default useHomepageData;
