import { useAuth0 } from '@auth0/auth0-react';
import { ButtonSave, ButtonSaveWrapper } from '../ResponsibleGambling.styled';
import Button from '#/components/common/Button';
import useUserData from '#/hooks/useUserData';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '#/utils/store';
import { toast } from 'react-toastify';
import {
  BetLimitState,
  setBetLimit,
  setTimeLimit,
  setTimeoutPeriod,
} from '#/utils/slices/responsibleGamblingSlice';
import { useKycStatus } from '#/components/SlipsDrawers/Components/useKycStatus';
import { useState } from 'react';
import usePostSettingsDataLoader from '#/utils/dataLoaders/usePostSettingsUserData';
import { useQuerySmartContract } from '#/hooks/useQuerySmartContract';
import {
  convertSecondsToTimeout,
  convertTimeLimitToMs,
  convertTimeoutToSeconds,
} from '../ResponsibleGambling.utils';
import useAccessToken from '#/utils/dataLoaders/useAccessToken';
import { deactivateUser } from '#/utils/backend';
import { setIsModalOpen } from '#/utils/slices/walletConnectionSlice';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import { useExecute } from '#/hooks/useExecute';
import { LAST_NOTIFICATION_KEY } from '#/hooks/useTimeLimitNotification';
import dayjs from 'dayjs';

function SaveButton({
  reminderFrequency,
  timeoutPeriod: timeOutPeriodLocal,
  betLimits,
}: {
  reminderFrequency?: string;
  timeoutPeriod?: string | null;
  betLimits?: BetLimitState;
}) {
  const { isAuthenticated, hashedUserId, user } = useUserData();
  const { loginWithRedirect, logout } = useAuth0();
  const { account, cosmWasmClient } = useCosmosKitWallet();
  const address = account?.address;
  const { executeMultiple } = useExecute();

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { kycToken, isLoading: kycLoading } = useKycStatus(true);
  const tab = searchParams.get('tab');
  const [isLoading, setIsLoading] = useState(false);
  const { timeLimit, timeoutPeriod } = useSelector(
    (state: RootState) => state.responsibleGambling,
  );
  const accountDeactivated = user?.responsibleGambling.accountDeactivated;

  const { data: accessToken } = useAccessToken();

  const { mutate: postSettings, isLoading: isPostingSettings } =
    usePostSettingsDataLoader();

  const queryMsg = { account: { user_id: hashedUserId } };

  const { data, refetch } = useQuerySmartContract<{
    gambling_limits: {
      daily: string;
      weekly: string;
      monthly: string;
    };
  }>(queryMsg, !!hashedUserId);

  const hasDailyLimitChanged =
    betLimits?.dailyLimit !== Number(data?.gambling_limits.daily ?? '0');
  const hasWeeklyLimitChanged =
    betLimits?.weeklyLimit !== Number(data?.gambling_limits.weekly ?? '0');
  const hasMonthlyLimitChanged =
    betLimits?.monthlyLimit !== Number(data?.gambling_limits.monthly ?? '0');

  const isSaveDisabled = () => {
    switch (tab) {
      case 'betLimit':
        return (
          accountDeactivated ||
          isLoading ||
          isPostingSettings ||
          (!hasDailyLimitChanged &&
            !hasWeeklyLimitChanged &&
            !hasMonthlyLimitChanged) // Disable if no bet limits have changed
        );

      case 'timeLimit':
        return (
          accountDeactivated ||
          isLoading ||
          isPostingSettings ||
          convertTimeLimitToMs(reminderFrequency) ===
            timeLimit.reminderFrequency // Disable if no change in time limit
        );

      case 'timeOut':
        return (
          accountDeactivated ||
          isLoading ||
          isPostingSettings ||
          convertSecondsToTimeout(timeoutPeriod.duration) === timeOutPeriodLocal
        );

      case 'accountDeactivation':
        return isLoading || isPostingSettings || accountDeactivated; // Disable if an action is executing or settings are being posted

      default:
        return true; // Disable by default if the tab is not recognized
    }
  };

  const handleSaveClick = async () => {
    if (!address) {
      dispatch(setIsModalOpen(true));
      return;
    }

    if (!isAuthenticated) {
      loginWithRedirect();
      return;
    }

    try {
      switch (tab) {
        case 'betLimit':
          await handleBetLimitSave();
          break;

        case 'timeLimit':
          await handleTimeLimitSave();
          break;

        case 'timeOut':
          await handleTimeOutSave();
          break;

        case 'accountDeactivation':
          await handleAccountDeactivation();
          break;

        default:
          toast.error('Unknown action.');
          break;
      }
    } catch (error) {
      toast.error('An error occurred while saving. Please try again.');
      console.error(error);
    }
  };

  const handleBetLimitSave = async () => {
    try {
      const payload: Record<string, string | null> = {
        token: kycToken,
      };

      if (!cosmWasmClient) {
        throw new Error('Signing client not found');
      }

      // If no limits have changed, avoid making the API call
      if (
        !hasDailyLimitChanged &&
        !hasWeeklyLimitChanged &&
        !hasMonthlyLimitChanged
      ) {
        toast.info('No changes to save.');
        return;
      }
      setIsLoading(true);
      // Preparing payload only for changed limits
      if (hasDailyLimitChanged && betLimits?.dailyLimit !== null) {
        payload.daily = betLimits?.dailyLimit
          ? String(betLimits?.dailyLimit)
          : '0';
      }
      if (hasWeeklyLimitChanged && betLimits?.weeklyLimit !== null) {
        payload.weekly = betLimits?.weeklyLimit
          ? String(betLimits?.weeklyLimit)
          : '0';
      }
      if (hasMonthlyLimitChanged && betLimits?.monthlyLimit !== null) {
        payload.monthly = betLimits?.monthlyLimit
          ? String(betLimits?.monthlyLimit)
          : '0';
      }

      const msg = {
        set_gambling_limits: payload,
      };

      executeMultiple([{ msg }], {
        onSuccess: () => {
          toast.success('Bet limits have been saved.');
          refetch();
          dispatch(setBetLimit(betLimits ? betLimits : ({} as BetLimitState)));
          setIsLoading(false);
        },
        onError: () => {
          toast.error('Failed to save bet limits.');
          setIsLoading(false);
        },
      });
    } catch (err: unknown) {
      toast.error((err as Error).message);
      setIsLoading(false);
    }
  };

  const handleTimeLimitSave = async () => {
    try {
      setIsLoading(true);
      const timeLimit = convertTimeLimitToMs(reminderFrequency);
      const settingsData = {
        timeLimit,
      };

      postSettings(settingsData, {
        onSuccess: () => {
          toast.success(`Reminder frequency changed to: ${reminderFrequency}`);
          if (reminderFrequency === 'None') {
            localStorage.removeItem(LAST_NOTIFICATION_KEY);
          }
          dispatch(setTimeLimit({ reminderFrequency: timeLimit }));
          setIsLoading(false);
        },
        onError: (error) => {
          toast.error('Failed to update time limit.');
          console.error('Error:', error);
          setIsLoading(false);
        },
      });
    } catch (error) {
      toast.error('Error occurred while saving time limit.');
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  const handleTimeOutSave = async () => {
    try {
      setIsLoading(true);
      const timeOutData = {
        timeOutDuration: convertTimeoutToSeconds(timeOutPeriodLocal ?? null),
      };

      postSettings(timeOutData, {
        onSuccess: () => {
          toast.success('Timeout duration updated successfully.');
          dispatch(
            setTimeoutPeriod({
              appliedAt: dayjs().toISOString(),
              duration: convertTimeoutToSeconds(timeOutPeriodLocal ?? null),
            }),
          );
          setIsLoading(false);
        },
        onError: (error) => {
          toast.error('Failed to update timeout duration.');
          console.error('Error:', error);
          setIsLoading(false);
        },
      });
    } catch (error) {
      toast.error('Error occurred while saving timeout.');
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  const handleAccountDeactivation = async () => {
    try {
      setIsLoading(true);
      if (!accessToken) {
        toast.error('Access token is not defined.');
        return;
      }
      await deactivateUser(accessToken);
      setIsLoading(false);
      toast.info('Account Deactivated');
      logout({ logoutParams: { returnTo: window.location.origin } });
    } catch (error) {
      toast.error('Failed to deactivate account.');
      setIsLoading(false);
      console.error('Error deactivating account:', error);
    } finally {
      setIsLoading(false);
    }
  };

  function getButtonText() {
    if (accountDeactivated) {
      return 'Account Deactivated';
    }
    if (!isAuthenticated) {
      return 'Login to Save';
    }

    if (!address) {
      return 'Connect to Wallet';
    }

    if (kycLoading) {
      return 'Loading...';
    }

    if (tab === 'accountDeactivation') {
      return 'Deactivate Account';
    }

    return 'Save';
  }

  return (
    <div className={ButtonSaveWrapper}>
      <Button
        className={ButtonSave}
        id="save"
        onClick={handleSaveClick}
        disabled={kycLoading || isSaveDisabled()}
      >
        {isLoading || kycLoading || isLoading || isPostingSettings ? (
          <div className="flex items-center mr-4">
            <div className="PageLoader !w-4 !h-4"></div>
          </div>
        ) : null}
        {getButtonText()}
      </Button>
    </div>
  );
}

export default SaveButton;
