import ActivityCard from '#/components/common/ActivityCard/ActivityCard.component';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';
import {
  ActivityOutcome,
  Bet,
  BetsData,
  getStatusType,
  isActiveBet,
  isSettledBet,
} from '#/utils/backend';
import WithData from './Wrapper/WrapWithTransitionStates';
import {
  LoaderBg,
  LoaderCardContainer,
} from './common/LoaderStyle/LoaderStyle.styled';
import EmptyList from './common/EmptyStates/EmptyState.component';
import {
  DOWNLOAD_ICON,
  UPLOAD_ICON,
} from '#/constants/common/images.constants';
import { getMarketNameById, getOutcomeName } from '#/utils/marketsMap';
import { useAppSelector } from '#/utils/store';
import {
  MARKET_TYPE_ID_URL_PARAM,
  OUTCOME_URL_PARAM,
  SPORT_URL_PARAM,
} from '#/utils/slices/activityFiltersSlice';
import { useSearchParams } from 'react-router-dom';
import useUserData from '#/hooks/useUserData';
import { typedOddValue } from '#/utils/sgeUtils/sgeUtils';

function BetActivityCardsWithData({
  searchInput,
  activeSubItem,
  data = [],
}: {
  searchInput: string;
  activeTab: string;
  activeSubItem: string;
  data: Bet[];
}) {
  const { user } = useUserData();
  const [searchParams] = useSearchParams();
  const activityFilters = useAppSelector((state) => state.activityFilters);

  // Function to consolidate filtering logic
  const filterBets = (betsData: Bet[]) => {
    if (!betsData || betsData.length === 0) return [];

    const outcomes = searchParams.getAll(OUTCOME_URL_PARAM);
    const marketTypeIds = searchParams
      .getAll(MARKET_TYPE_ID_URL_PARAM)
      .map(Number);
    const sports = searchParams.getAll(SPORT_URL_PARAM);

    // Allowed Outcomes from state filters
    const allowedOutcomes: ActivityOutcome[] = [];
    Object.keys(activityFilters.outcomes).forEach((outcome) => {
      const typedOutcome = outcome as ActivityOutcome;
      if (activityFilters.outcomes[typedOutcome]) {
        allowedOutcomes.push(typedOutcome);
      }
    });

    // Allowed Market Type IDs from state filters
    const allowedMarketTypeIds: number[] = [];
    Object.keys(activityFilters.marketTypeIds).forEach((marketTypeId) => {
      const typedMarketTypeId = parseInt(marketTypeId);
      if (activityFilters.marketTypeIds[typedMarketTypeId]) {
        allowedMarketTypeIds.push(typedMarketTypeId);
      }
    });

    // Start filtering the bets
    const filteredBets = betsData.filter((bet) => {
      const betOutcome = bet.result as ActivityOutcome;

      // Tab Filter (Active/Settled)
      const fitsTheTab =
        activeSubItem === 'activeBets' ? isActiveBet(bet) : isSettledBet(bet);

      // Search Filter
      const fitsSearch =
        searchInput?.length > 0
          ? bet.fixtureName.toLowerCase().includes(searchInput.toLowerCase())
          : true;

      // Outcome Filter
      const fitsOutcome =
        allowedOutcomes.length > 0
          ? allowedOutcomes.includes(betOutcome ?? 'NoResult')
          : true;

      // Market Type Filter
      const fitsMarketTypeId =
        allowedMarketTypeIds.length > 0
          ? allowedMarketTypeIds.includes(bet.marketTypeId)
          : true;

      // Outcome from URL Params (if any)
      const urlOutcomeMatch =
        outcomes.length > 0
          ? outcomes.includes(betOutcome) // Normalize to lowercase
          : true;

      // Market Type from URL Params (if any)
      const urlMarketTypeMatch =
        marketTypeIds.length > 0
          ? marketTypeIds.includes(bet.marketTypeId)
          : true;

      // Sports Filter from URL Params
      const urlSportMatch =
        sports.length > 0
          ? sports.includes(bet.sport) // Assuming fixtureName maps to sport
          : true;

      // Return true if all filters match
      return (
        fitsTheTab &&
        fitsSearch &&
        fitsOutcome &&
        fitsMarketTypeId &&
        urlOutcomeMatch &&
        urlMarketTypeMatch &&
        urlSportMatch
      );
    });

    return filteredBets;
  };

  // Apply filters to the provided data
  const filteredBetsData = data ? filterBets(data) : [];

  // Render empty state if no bets match the filters
  if (!filteredBetsData.length) {
    return <EmptyList id="bets" />;
  }

  const getBetState = (state: string, bet: Bet) => {
    if (bet.result === 'NoResult' && activeSubItem === 'settledBets')
      return 'Failed';
    return activeSubItem === 'settledBets' ? bet.result : state;
  };

  return (
    <>
      {filteredBetsData.map((bet) => {
        const outcomePosition = parseInt(bet.outcomeId.split('-')[1] || '0');
        const statusType = getStatusType(bet);
        return (
          <ActivityCard
            key={bet.id}
            statusText={getBetState(bet.state, bet)}
            statusType={statusType}
            dateText={formatDateToDesiredFormat(bet.submittedAt || '')}
            title={`${getOutcomeName(bet.marketTypeId, outcomePosition)} @ ${typedOddValue(user?.oddsFormat || 'decimal', Number(bet.odds))}`}
            titleText={`${getMarketNameById(bet.marketTypeId)}`}
            subTitle={bet.fixtureName}
            footerInfo={[
              { iconSrc: UPLOAD_ICON, text: `${bet.stake} SGE` },
              { iconSrc: DOWNLOAD_ICON, text: `${bet.potentialWinnings} SGE` },
            ]}
          />
        );
      })}
    </>
  );
}

const LoadingComponent = () => {
  return (
    <div className="px-0">
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5 w-11/12 mb-2`}></div>
          <div className="w-full flex items-center">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
        </div>
      </div>
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5 w-10/12 mb-2`}></div>
          <div className="w-full flex items-center">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
        </div>
      </div>
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5 w-11/12 mb-2`}></div>
          <div className="w-full flex items-center">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BetActivityCards = WithData<BetsData>(
  BetActivityCardsWithData,
  LoadingComponent,
  true,
);

export default BetActivityCards;
